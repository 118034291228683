import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import shortid from 'shortid';
import { Button, Content } from '@/components/atoms';
import { ButtonGroup, FileInputLabel } from '@/components/molecules';
import { useDataCrud, useInput } from '@/hooks';
import { fireStorage } from '@/firebaseInit';
import { setIsLoading } from '@/shared/redux/common';
import { setTrendReportInfo, trendReportInitialState } from '@/shared/redux/trendReport';

export default function TrendReportSettingComp({ pageInfo }) {
  const dispatch = useDispatch();
  const { currPageInfo } = useSelector(state => state.common);
  const { modifyData, uploadFile } = useDataCrud(currPageInfo);
  const [newTrendReport, setTrendReport] = useInput({
    ...trendReportInitialState.trendReportInfo
  });
  const { getDataInfo } = useDataCrud();

  // 해당 id 데이터
  useEffect(() => {
    (async () => {
      const infoData = await getDataInfo(pageInfo, 'trendReport');
      dispatch(setTrendReportInfo(infoData));
      dispatch(setIsLoading(false));
    })();

    return () => {
      dispatch(setTrendReportInfo(trendReportInitialState.trendReportInfo));
    };
  }, [getDataInfo, dispatch, pageInfo, setTrendReport]);

  function handleFileUpload(e) {
    const { files } = e.target;

    if (!files[0]?.name) {
      return false;
    }
    if (files[0].type !== 'application/pdf') {
      alert('pdf 파일만 업로드 가능합니다.');
      return false;
    }

    setTrendReport({
      ...newTrendReport,
      file: files[0]
    });
  }

  async function submitTrendReport() {
    const { file } = newTrendReport;
    const name = 'trendReport';

    if (!file) {
      alert('파일을 먼저 추가해주세요.');
    }

    // submit
    dispatch(setIsLoading(true));

    // old file cleanUp
    const storageRef = fireStorage.ref('premium/trendReport');
    storageRef.listAll().then(listResults => {
      const promises = listResults.items.map(item => {
        return item.delete();
      });
      Promise.all(promises);
    });

    // add file
    const uploadFileUrl = await uploadFile(
      pageInfo,
      name,
      `${name}_${shortid.generate()}.pdf`,
      file
    );

    await modifyData(pageInfo, name, {
      file: uploadFileUrl
    });
  }

  return (
    <>
      <Content>
        <FileInputBox>
          <FileInputLabel
            id='file-input'
            labelText='PDF 파일 추가'
            file={newTrendReport.file}
            onChange={handleFileUpload}
          />
        </FileInputBox>
      </Content>
      <ButtonGroup>
        <Button theme='primary' onClickCB={submitTrendReport}>
          등록
        </Button>
      </ButtonGroup>
    </>
  );
}

//* style

const FileInputBox = styled.div`
  margin-bottom: 20px;
  & > .file-label {
    padding: 10px;
    color: var(--color-white-1);
    box-sizing: border-box;
    background: var(--color-green-5);
    border-radius: 8px;
  }
  & > .file-input {
    display: none;
  }
`;
