import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export default function ContentTitleComp({ className, children, isLine = true }) {
  return (
    <ContentTitleBox className={className}>
      <ContentTitle>{children}</ContentTitle>
      {isLine ? <ContentTitleLine /> : null}
    </ContentTitleBox>
  );
}

//* props
ContentTitleComp.propTypes = {
  /** 클래스 명 */
  className: PropTypes.string,
  /** 컨텐츠 타이틀 텍스트 */
  children: PropTypes.node,
  /** 하단 라인 표시 여부 */
  isLine: PropTypes.bool
};

//* style
const ContentTitleBox = styled.div`
  margin-bottom: 30px;
`;
const ContentTitle = styled.p`
  font-weight: 700;
  font-size: 20px;
  color: var(--color-black-2);
  margin-bottom: 17px;
  letter-spacing: -2%;
`;
const ContentTitleLine = styled.span`
  display: block;
  width: 100%;
  height: 1px;
  background: var(--color-blue-5);
  opacity: 0.2;
`;
