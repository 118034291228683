import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fireErrorMessage } from '@/utils/firebase';
import { setIsLoading } from '@/shared/redux/common';

export default function useFireResult() {
  const dispatch = useDispatch();

  const handleSuccess = useCallback(
    (text, isCustomText) => {
      dispatch(setIsLoading(false));
      isCustomText ? toast['success'](`${text}`) : toast['success'](`${text} 완료되었습니다.`);
    },
    [dispatch]
  );

  const handleError = useCallback(
    (errCode, text, isCustomText) => {
      dispatch(setIsLoading(false));

      if (!errCode && text) {
        isCustomText
          ? toast['error'](`${text}`)
          : toast['error'](`${text} 중 에러가 발생하였습니다.`);
        return false;
      }

      toast['error'](`${fireErrorMessage(errCode)}`);
    },
    [dispatch]
  );

  return { handleSuccess, handleError };
}
