import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { UserTemp } from '@/components/templates';
import { setPageDepthList } from '@/shared/redux/common';

export default function UserPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const { dataId, mode } = params;

  // pageDepth
  useEffect(() => {
    dispatch(
      setPageDepthList({
        pageName: '유저 관리',
        mode,
        dataId
      })
    );
  }, [dispatch, mode, dataId]);

  return <UserTemp />;
}
