import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { Select, Button, Content } from '@/components/atoms';
import { Pagination } from '@/components/molecules';
import { viewCountList } from '@/shared/commonData';
import { createQueryString, getUrlQueryValue, getCurrPageList, convertValue } from '@/utils/common';

export default function TableComp({
  titleList = [],
  currList = [],
  totalCnt = 0,
  onClickBtn,
  isSubmitBtn = true,
  submitPage = ''
}) {
  const history = useHistory();
  const location = useLocation();
  const currPageList = getCurrPageList(currList, location);
  const viewCount = Number(getUrlQueryValue(location, 'viewCount', viewCountList[0].value));
  const currPageNum = Number(getUrlQueryValue(location, 'page', 1));

  function handleSelectOption(value) {
    const search = createQueryString({ viewCount: value }, location.search);
    history.push(search);
  }

  function submitItem() {
    history.push({
      pathname: `/${submitPage}/write`
    });
  }

  return (
    <Content>
      <Table>
        <thead>
          <TableTr>
            {/* 
              - 버튼 항목들은 타이틀 표시 x 
              - 해당 항목의 isNotList 가 true 인 경우 표시 x
            */}
            {titleList
              .filter(val => !val.isNotList)
              .map((v, i) => (
                <TableTh key={i} width={v.width}>
                  {v.kind !== 'button' ? v.title : ''}
                </TableTh>
              ))}
          </TableTr>
        </thead>
        <Tbody>
          {currPageList.map((data, i) => {
            return (
              <TableTr key={i}>
                {titleList
                  .filter(val => !val.isNotList)
                  .map((val, idx) => {
                    let value = data[val.id];
                    value = convertValue(val.kind, value);
                    let title = value;

                    if (val.kind === 'button') {
                      title = '';
                      value = (
                        <Button theme={val.theme} onClickCB={() => onClickBtn(data.id, val.id)}>
                          {val.title}
                        </Button>
                      );
                    }

                    return (
                      <TableTd key={idx} kind={val.kind} title={title}>
                        {value}
                      </TableTd>
                    );
                  })}
              </TableTr>
            );
          })}
        </Tbody>
      </Table>
      <TableInfo>
        <TablePagination>
          <Pagination totalCnt={totalCnt} viewCount={viewCount} currPage={currPageNum} />
        </TablePagination>
        <TableInfoRight>
          <Select
            width='165px'
            optionList={viewCountList}
            initSelect={viewCount}
            onClick={handleSelectOption}
            isResetOption={false}
            isUpDepth={true}
          />
          {isSubmitBtn && (
            <WriteButton theme='primary' onClickCB={submitItem} isOutline={true}>
              등록
            </WriteButton>
          )}
        </TableInfoRight>
      </TableInfo>
    </Content>
  );
}

//* props
TableComp.propTypes = {
  /** Table th 리스트 */
  titleList: PropTypes.array,
  /** 전체 데이터 리스트 */
  currList: PropTypes.array,
  /** 전체 데이터 총 개수 */
  totalCnt: PropTypes.number,
  /** Table 버튼 클릭 이벤트 */
  onClickBtn: PropTypes.func,
  /** '등록' 버튼 표시 여부 */
  isSubmitBtn: PropTypes.bool
};

//* style
const Table = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
`;
const ThTdStyle = css`
  padding: 12px 20px;
  box-sizing: border-box;
  font-weight: 500;
`;
const TableTh = styled.th`
  ${ThTdStyle}
  font-size:var(--font-size-3);
  font-weight: 700;
  padding-top: 0;
  padding-bottom: 20px;
`;
const Tbody = styled.tbody`
  border-top: 1px solid rgba(var(--color-blue-5-rgb), 0.2);
`;
const TableTr = styled.tr`
  border-bottom: 1px solid rgba(var(--color-blue-5-rgb), 0.2);
`;
const TableTd = styled.td`
  ${ThTdStyle}
  ${({ kind }) =>
    kind === 'button' &&
    css`
      padding-left: 10px;
      padding-right: 0;
    `};
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const TableInfo = styled.div`
  position: relative;
  display: flex;
  margin-top: 20px;
`;
const TablePagination = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
const TableInfoRight = styled.div`
  display: flex;
  margin-left: auto;
`;
const WriteButton = styled(Button)`
  margin-left: 20px;
`;
