import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  Content,
  List,
  ContentTitle,
  Select,
  ContentItem,
  Input
} from '@/components/atoms';
import { ButtonGroup, ContentInputItem, ContentTextItem } from '@/components/molecules';
import { useDataCrud, useInput } from '@/hooks';
import { setUserInfo, userInitialInfo } from '@/shared/redux/user';
import { setIsLoading } from '@/shared/redux/common';
import { encryptionPassword } from '@/utils/common';
import { userSupplierInfo } from '@/shared/commonData';

export default function UserWriteComp({ pageInfo, deleteDataCB }) {
  const dispatch = useDispatch();
  const { dataId } = useParams();
  const { getDataInfo, modifyData } = useDataCrud();
  const [newUserInfo, setNewUserInfo, handleChangeInfo] = useInput({
    ...userInitialInfo.userInfo
  });
  const {
    id: doc_id,
    name,
    email,
    password,
    //phoneAreaCode,
    phoneNum,
    companyName,
    companyPosition,
    companyPhoneAreaCode,
    companyPhoneNum,
    supplier,
    isPremium
  } = newUserInfo;

  // 해당 id 데이터
  useEffect(() => {
    if (dataId) {
      (async () => {
        const infoData = await getDataInfo(pageInfo, dataId);

        dispatch(setUserInfo(infoData));
        dispatch(setIsLoading(false));
        setNewUserInfo({
          ...newUserInfo,
          ...infoData,
          password: ''
        });
      })();

      return () => {
        dispatch(setUserInfo(userInitialInfo.userInfo));
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataInfo, dataId, dispatch, pageInfo, setNewUserInfo]);

  const userPremiumOptionList = [
    {
      text: '적용',
      value: true
    },
    {
      text: '해제',
      value: false
    }
  ];

  const userPhoneAreaCodeList = [
    {
      text: '010',
      value: '010'
    },
    {
      text: '031',
      value: '031'
    },
    {
      text: '02',
      value: '02'
    }
  ];

  function encryptionInfo() {
    const userInfo = {
      ...newUserInfo,
      password: encryptionPassword(password)
    };

    if (!password) delete userInfo.password;
    return userInfo;
  }

  function handleClickSelectOptionIsPremium(value, text) {
    setNewUserInfo({
      ...newUserInfo,
      isPremium: value
    });
  }

  // function handleClickSelectOptionPhoneAreaCode(value, text) {
  //   setNewUserInfo({
  //     ...newUserInfo,
  //     phoneAreaCode: value
  //   });
  // }

  function handleClickSelectOptionCompanyPhoneAreaCode(value, text) {
    setNewUserInfo({
      ...newUserInfo,
      companyPhoneAreaCode: value
    });
  }

  return (
    <div>
      <Content>
        <ContentTitle>유저 정보</ContentTitle>
        <List>
          <ContentInputItem
            labelTitle='이름'
            name='name'
            value={name}
            onChange={handleChangeInfo}
          />
          <ContentTextItem labelTitle='이메일' content={email} isWritePage={true} />
          <ContentInputItem
            labelTitle='비밀번호'
            name='password'
            type='password'
            value={password}
            onChange={handleChangeInfo}
          />
          <ContentItem labelTitle='휴대폰 번호'>
            <SelectInputBox>
              {/* <Select
                name='phoneAreaCode'
                optionList={userPhoneAreaCodeList}
                initSelect={phoneAreaCode}
                onClick={handleClickSelectOptionPhoneAreaCode}
                isResetOption={false}
              /> */}
              <InputComp name='phoneNum' value={phoneNum} onChange={handleChangeInfo} />
            </SelectInputBox>
          </ContentItem>
          <ContentInputItem
            labelTitle='회사명'
            name='companyName'
            value={companyName}
            onChange={handleChangeInfo}
          />
          <ContentItem labelTitle='회사 전화번호'>
            <SelectInputBox>
              <Select
                name='companyPhoneAreaCode'
                optionList={userPhoneAreaCodeList}
                initSelect={companyPhoneAreaCode}
                onClick={handleClickSelectOptionCompanyPhoneAreaCode}
                isResetOption={false}
              />
              <InputComp
                name='companyPhoneNum'
                value={companyPhoneNum}
                onChange={handleChangeInfo}
              />
            </SelectInputBox>
          </ContentItem>
          <ContentInputItem
            labelTitle='회사 직급'
            name='companyPosition'
            value={companyPosition}
            onChange={handleChangeInfo}
          />
          <ContentTextItem
            labelTitle='로그인 유형'
            content={userSupplierInfo[supplier]}
            isWritePage={true}
          />
          <ContentItem labelTitle='프리미엄 회원 여부'>
            <Select
              name='isPremium'
              optionList={userPremiumOptionList}
              initSelect={isPremium}
              onClick={handleClickSelectOptionIsPremium}
              isResetOption={false}
            />
          </ContentItem>
        </List>
      </Content>
      <ButtonGroup>
        <>
          <Button theme='warning' onClickCB={() => modifyData(pageInfo, doc_id, encryptionInfo())}>
            수정
          </Button>
          <Button theme='danger' onClickCB={() => deleteDataCB(doc_id)}>
            삭제
          </Button>
        </>
      </ButtonGroup>
    </div>
  );
}

//* style
const InputComp = styled(Input)`
  background: var(--color-input-bg);
  display: block;
`;
const SelectInputBox = styled.div`
  display: flex;
  & > .select {
    margin-right: 15px;
  }
`;
