import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Image } from '@/components/atoms';

export default function LogoComp({
  className,
  isLink = true,
  link = '/',
  width = '88px',
  height = '16px',
  color = 'var(--color-white-1)'
}) {
  return (
    <LogoWrap width={width} height={height} className={className}>
      {isLink ? (
        <LogoLink to={link}>
          <LogoImage
            url={`${process.env.PUBLIC_URL}/images/logo.svg`}
            color={color}
            alt='logo-img'
          />
        </LogoLink>
      ) : (
        <LogoImage url={`${process.env.PUBLIC_URL}/images/logo.svg`} color={color} alt='logo-img' />
      )}
    </LogoWrap>
  );
}

//* props
LogoComp.propTypes = {
  /** 클래스 명 */
  className: PropTypes.string,
  /** 링크 여부 */
  isLink: PropTypes.bool,
  /** 링크 경로 */
  link: PropTypes.string,
  /** 로고 넓이 */
  width: PropTypes.string,
  /** 로고 높이 */
  height: PropTypes.string,
  /** 로고 색상 */
  color: PropTypes.string
};

//* style
const LogoWrap = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;
const LogoLink = styled(Link)`
  display: block;
  width: inherit;
  height: inherit;
`;
const LogoImage = styled(Image)`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;
