import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { imgCompression } from '@/utils/common';

function WysiwygComp(props, ref) {
  const { value, onChange } = props;
  const quillRef = useRef(null);
  const files = useRef([]);

  useImperativeHandle(ref, () => files.current);

  const imageHandler = async () => {
    const input = document.createElement('input');
    const image = new Image();
    const fileReader = new FileReader();

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      const file = input.files[0];

      image.src = URL.createObjectURL(file);
    };

    fileReader.onload = async e => {
      const file = input.files[0];
      const range = quillRef.current.getEditorSelection();

      const { compressedFile, compressedDataUrl } = await imgCompression(file);

      files.current = files.current.concat(
        Object.assign(compressedFile, { result: compressedDataUrl })
      );

      quillRef.current.getEditor().insertEmbed(range.index, 'image', compressedDataUrl);
      quillRef.current.getEditor().setSelection(range.index + 1);
      quillRef.current.focus();
    };

    image.onload = async e => {
      const file = input.files[0];
      const { compressedFile } = await imgCompression(file);

      fileReader.readAsDataURL(compressedFile);
    };

    image.onerror = e => {
      alert('이미지 파일만 업로드 가능합니다');
      return false;
    };
  };

  const modulesRef = useRef({
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6] }, { font: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image', 'video'],
        ['clean'],
        ['code-block']
      ],
      handlers: {
        image: imageHandler
      }
    }
  });

  return (
    <Wysiwyg>
      <ReactQuill
        ref={quillRef}
        value={value}
        onChange={onChange}
        placeholder='내용을 입력해주세요.'
        modules={modulesRef.current}
      />
    </Wysiwyg>
  );
}
export default forwardRef(WysiwygComp);

//* props
WysiwygComp.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};

//* style
const Wysiwyg = styled.div`
  .ql-toolbar,
  .ql-container {
    border: 1px solid rgba(var(--color-blue-5-rgb), 0.2);
  }
`;
