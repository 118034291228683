import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import shortid from 'shortid';
import { Button, Content, ContentTitle, List } from '@/components/atoms';
import { ButtonGroup, ContentInputItem } from '@/components/molecules';
import { useDataCrud, useInput } from '@/hooks';
import { setCareerCategoryInfo, careerInitialInfo } from '@/shared/redux/career';
import { setIsLoading } from '@/shared/redux/common';
import { convertEnterToBrTag, convertBrTagToEnter } from '@/utils/common';

export default function CareerCategoryWriteComp({ pageInfo, deleteDataCB }) {
  const dispatch = useDispatch();
  const { dataId } = useParams();
  const { getDataInfo, modifyData, writeData } = useDataCrud();
  const [newUserInfo, setNewUserInfo, handleChangeInfo] = useInput({
    ...careerInitialInfo.careerCategoryInfo
  });
  const {
    id: doc_id,
    name,
    parentCategory,
    shortDescription,
    description,
    qualification,
    employment,
    submission
  } = newUserInfo;

  // 해당 id 데이터
  useEffect(() => {
    if (dataId) {
      (async () => {
        const infoData = await getDataInfo(pageInfo, dataId);
        setNewUserInfo(infoData);
        dispatch(setCareerCategoryInfo(infoData));
        dispatch(setIsLoading(false));
      })();

      return () => {
        setNewUserInfo(careerInitialInfo.careerCategoryInfo);
        dispatch(setCareerCategoryInfo(careerInitialInfo.careerCategoryInfo));
      };
    }
  }, [getDataInfo, dataId, dispatch, pageInfo, setNewUserInfo]);

  function submitCareerCategory(type) {
    const { description, qualification, employment, submission } = newUserInfo;
    const newId = shortid.generate();

    const newInfo = {
      ...newUserInfo,
      id: doc_id ? doc_id : newId,
      shortDescription: convertEnterToBrTag(shortDescription),
      description: convertEnterToBrTag(description),
      qualification: convertEnterToBrTag(qualification),
      employment: convertEnterToBrTag(employment),
      submission: convertEnterToBrTag(submission)
    };

    type === 'modify' ? modifyData(pageInfo, doc_id, newInfo) : writeData(pageInfo, newId, newInfo);
  }

  return (
    <div>
      <Content>
        <ContentTitle>카테고리 정보</ContentTitle>
        <List>
          <ContentInputItem
            labelTitle='상위 카테고리'
            name='parentCategory'
            value={convertBrTagToEnter(parentCategory)}
            onChange={handleChangeInfo}
          />
          <ContentInputItem
            labelTitle='카테고리명'
            name='name'
            value={name}
            onChange={handleChangeInfo}
          />
          <ContentInputItem
            isTextArea={true}
            labelTitle='간략 설명'
            name='shortDescription'
            value={convertBrTagToEnter(shortDescription)}
            onChange={handleChangeInfo}
          />
          <ContentInputItem
            isTextArea={true}
            labelTitle='설명글'
            name='description'
            value={convertBrTagToEnter(description)}
            onChange={handleChangeInfo}
          />
          <ContentInputItem
            isTextArea={true}
            labelTitle='지원 자격'
            name='qualification'
            value={convertBrTagToEnter(qualification)}
            onChange={handleChangeInfo}
          />
          <ContentInputItem
            isTextArea={true}
            labelTitle='제출 서류'
            name='submission'
            value={convertBrTagToEnter(submission)}
            onChange={handleChangeInfo}
          />
          <ContentInputItem
            isTextArea={true}
            labelTitle='고용 형태'
            name='employment'
            value={convertBrTagToEnter(employment)}
            onChange={handleChangeInfo}
          />
        </List>
      </Content>
      <ButtonGroup>
        {dataId ? (
          <>
            <Button theme='warning' onClickCB={() => submitCareerCategory('modify')}>
              수정
            </Button>
            <Button theme='danger' onClickCB={() => deleteDataCB(doc_id)}>
              삭제
            </Button>
          </>
        ) : (
          <Button theme='primary' onClickCB={() => submitCareerCategory('write')}>
            등록
          </Button>
        )}
      </ButtonGroup>
    </div>
  );
}
