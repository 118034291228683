import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TrendReportTemp } from '@/components/templates';
import { setPageDepthList } from '@/shared/redux/common';

export default function TrendReportPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const { dataId, mode } = params;

  // pageDepth
  useEffect(() => {
    dispatch(
      setPageDepthList({
        pageName: 'Premium',
        depthPageName: 'Trand Report',
        mode,
        dataId
      })
    );
  }, [dispatch, mode, dataId]);

  return <TrendReportTemp />;
}
