import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Input } from '@/components/atoms';
import { getUrlQueryValue, createQueryString } from '@/utils/common';

/**
 * - 특정 항목을 검색하는 경우 사용되는 컴포넌트입니다.
 */
export default function SearchInputComp({ queryKey = '', searchValue, inputWidth }) {
  const location = useLocation();
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState(getUrlQueryValue(location, queryKey, ''));
  const parentCategory = getUrlQueryValue(location, 'parentCategory');
  const category = getUrlQueryValue(location, 'category');

  function handleChangeSearchQuery(e) {
    setSearchQuery(e.target.value);
  }

  useEffect(() => {
    setSearchQuery(getUrlQueryValue(location, queryKey, ''));
  }, [location, queryKey]);

  function handleSearchQuery(e) {
    if (e.keyCode && e.keyCode !== 13) return false;

    let search;
    if (location.pathname.includes('career')) {
      search = createQueryString({ name: searchQuery, parentCategory, category });
    } else {
      search = createQueryString({ name: searchQuery });
    }

    history.push(search);
  }

  return (
    <SearchInputBox>
      <SearchInput
        placeholder={`${searchValue} 입력해주세요.`}
        onChange={handleChangeSearchQuery}
        value={searchQuery}
        width={inputWidth}
        onKeyDown={handleSearchQuery}
      />
      <Button theme='primary' isOutline={true} onClickCB={handleSearchQuery}>
        검색
      </Button>
    </SearchInputBox>
  );
}

//* props
SearchInputComp.propTypes = {
  /** 검색하려는 항목의 이름 */
  queryKey: PropTypes.string,
  /** Input - placeholder 에서 사용되는 검색 항목명 */
  searchValue: PropTypes.string,
  /** Input 넓잇값 */
  inputWidth: PropTypes.string
};

//* style
const SearchInputBox = styled.div`
  display: flex;
`;
const SearchInput = styled(Input)`
  margin-right: 20px;
  background: none;
  border: 1px solid var(--color-blue-5);
`;
