import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Table, SearchInput, TableTopArea } from '@/components/molecules';
import { CareerCategorySelect } from '@/components/organisms';
import { careerValueList, tableButtonList } from '@/shared/commonData';
import { useDataCrud } from '@/hooks';
import { setCareerList, setCareerCategoryList, careerInitialInfo } from '@/shared/redux/career';
import { setIsLoading } from '@/shared/redux/common';

export default function CareerTableComp({ pageInfo, deleteDataCB }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { careerList } = useSelector(state => state.career);
  const { currPage } = pageInfo;
  const { getDataList } = useDataCrud();
  const [filterCareerList, setFilterCareerList] = useState([]);
  const tableValueList = [
    ...careerValueList,
    ...tableButtonList.filter(button => button.id !== 'modifyBtn')
  ];

  // 리스트 데이터
  useEffect(() => {
    (async () => {
      const listData = await getDataList(pageInfo);
      const categoryListData = await getDataList({ collection: 'careerCategory' });

      // convert data
      const dataList = listData.map((item, idx) => {
        const currCategoryList = categoryListData.filter(it => item.categoryId === it.id)[0];
        const { name, parentCategory } = currCategoryList;

        return {
          ...item,
          category: name,
          parentCategory
        };
      });

      setFilterCareerList(dataList);
      dispatch(setCareerList(dataList));
      dispatch(setCareerCategoryList(categoryListData));
      dispatch(setIsLoading(false));
    })();

    return () => {
      setFilterCareerList([]);
      dispatch(setCareerList(careerInitialInfo.careerList));
    };
  }, [dispatch, getDataList, pageInfo, location]);

  // 테이블 버튼 클릭
  const handleClickBtn = useCallback(
    (dataId, btnId) => {
      const btnTypeInfo = {
        detailBtn: () => history.push(`/${currPage}/detail/${dataId}`),
        deleteBtn: () => deleteDataCB(dataId)
      };
      btnTypeInfo[btnId]();
    },
    [currPage, deleteDataCB, history]
  );

  return (
    <>
      <TableTopArea>
        <CareerCategorySelect
          pageInfo={pageInfo}
          dataList={careerList}
          setState={setFilterCareerList}
        />
        <SearchInput queryKey='name' searchValue='이름을' inputWidth='230px' />
      </TableTopArea>
      <Table
        titleList={tableValueList}
        totalCnt={filterCareerList.length}
        currList={filterCareerList}
        onClickBtn={handleClickBtn}
        isSubmitBtn={false}
      />
    </>
  );
}
