import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * - Table 컴포넌트의 상단 레이아웃 박스입니다.
 */
export default function TableTopAreaComp({ children }) {
  return <TableTopArea>{children}</TableTopArea>;
}

//* props
TableTopAreaComp.propTypes = {
  children: PropTypes.node
};

//* style
const TableTopArea = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
`;
