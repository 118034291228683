import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { List, Button } from '@/components/atoms';

/**
 * - formik 과 yup 을 사용하였습니다.
 * - `onSubmit` 에는 `formik.handleSubmit` 을 적용합니다.
 */
export default function FormComp({
  className,
  onSubmit,
  submitText = '확인',
  children,
  btnType = 'submit',
  btnWidth,
  onClickBtn
}) {
  return (
    <Form className={className} onSubmit={onSubmit}>
      <FormList>{children}</FormList>
      <SubmitBtn type={btnType} onClickCB={onClickBtn} width={btnWidth} theme='primary'>
        {submitText}
      </SubmitBtn>
    </Form>
  );
}

//* props
FormComp.propTypes = {
  /** 클래스 명 */
  className: PropTypes.string,
  /** onSubmit 이벤트 */
  onSubmit: PropTypes.func,
  /** submit button 텍스트 */
  submitText: PropTypes.string,
  /** form 리스트 하위 Item 컴포넌트 */
  children: PropTypes.node.isRequired,
  /** button 타입 */
  btnType: PropTypes.oneOf(['button', 'submit', 'reset']),
  /** button 클릭 이벤트 */
  onClickBtn: PropTypes.func
};

//* style
const Form = styled.form`
  text-align: center;
  display: inline-block;
`;
const FormList = styled(List)`
  margin-top: 15px;
  & > li {
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
const SubmitBtn = styled(Button)`
  margin-top: 40px;
`;
