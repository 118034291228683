import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'AUTH',
  initialState: {
    adminInfo: {
      email: '',
      name: '',
      type: ''
    }
  },
  reducers: {
    setAdminInfo: (state, action) => {
      const { email, name, type } = action.payload;
      state.adminInfo = {
        email,
        name,
        type
      };
    }
  }
});

export const { setAdminInfo } = authSlice.actions;
export default authSlice.reducer;
