import { css } from 'styled-components';

// custom scroll
export const CustomScrollStyle = css`
  &::-webkit-scrollbar {
    position: relative;
    z-index: 1;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #737679;
    border-radius: 12px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #5f6368;
  }
  &::-webkit-scrollbar-track {
    background: none;
    border: none;
  }
`;
