import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * - 이미지가 없는 경우 사용되는 컴포넌트입니다.
 */
export default function NoImageComp({ className, value = 'IMAGE' }) {
  return (
    <NoImage className={className}>
      <p>NO {String(value).toUpperCase()}</p>
    </NoImage>
  );
}

//* props
NoImageComp.propTypes = {
  /** 클래스 명 */
  className: PropTypes.string,
  /** 이미지 항목 */
  value: PropTypes.string
};

//* style
const NoImage = styled.div`
  width: 100px;
  height: 80px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  background: #aaa;
  color: #fff;
  user-select: none;
  padding: 10px;
  box-sizing: border-box;
  word-break: break-word;
`;
