import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Item, Label } from '@/components/atoms';

/**
 * - 상세, 등록, 수정 페이지에서 사용되는 컨텐츠 아이템 컴포넌트입니다.
 * - `labelTitle`, `labelWidth` 를 통해 Label 컴포넌트의 속성을 설정합니다.
 * - `children` 을 통해 일반 텍스트나 input 등을 적용할 수 있습니다.
 */
export default function ContentItemComp({
  className,
  children,
  marginBottom = '40px',
  labelTitle,
  labelWidth = '140px'
}) {
  return (
    <ContentItem className={className} marginBottom={marginBottom}>
      <LabelComp labelWidth={labelWidth}>{labelTitle}</LabelComp>
      {children}
    </ContentItem>
  );
}

//* props
ContentItemComp.propTypes = {
  /** 클래스 명 */
  className: PropTypes.string,
  /** 아이템 항목 */
  children: PropTypes.node,
  /** 하단 간격값 */
  marginBottom: PropTypes.string,
  /** 라벨 텍스트 */
  labelTitle: PropTypes.string,
  /** 라벨 넓이값 */
  labelWidth: PropTypes.string
};

//* style
const ContentItem = styled(Item)`
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  &:last-of-type {
    margin-bottom: 0;
  }
`;
const LabelComp = styled(Label)`
  width: ${({ labelWidth }) => labelWidth};
  font-weight: 700;
  flex: 0 0 auto;
`;
