import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

export default function ButtonComp({
  onClickCB,
  children,
  className,
  theme,
  isOutline,
  width = 'auto',
  isDisabled = false,
  ...props
}) {
  const currTheme = isOutline ? `${theme}-ol` : theme;

  return (
    <Button
      onClick={onClickCB}
      {...props}
      className={`${className} ${currTheme}`}
      width={width}
      disabled={isDisabled}
    >
      {children}
    </Button>
  );
}

ButtonComp.propTypes = {
  /** 버튼 타입 */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /** 버튼 넓이 */
  width: PropTypes.string,
  /** 버튼 클릭 이벤트 */
  onClickCB: PropTypes.func,
  /** 버튼 텍스트 */
  children: PropTypes.node,
  /** 버튼 클래스 명 */
  className: PropTypes.string,
  /** 버튼 테마 */
  theme: PropTypes.oneOf(['primary', 'warning', 'danger']),
  /** 버튼 outline 스타일 여부 */
  isOutline: PropTypes.bool,
  /** 버튼 disabled 여부 */
  isDisabled: PropTypes.bool
};

ButtonComp.defaultProps = {
  /** 클래스 명 */
  className: '',
  /** 버튼 type */
  type: 'button'
};

//* style
const ButtonDisabledStyle = css`
  background: var(--color-disabled);
  cursor: not-allowed;
`;
const ButtonOutlineDisabledStyle = css`
  cursor: not-allowed;
  border: 1px solid var(--color-disabled);
  color: var(--color-disabled);
`;

const Button = styled.button`
  width: ${({ width }) => width};
  display: inline-block;
  border: 0;
  cursor: pointer;
  padding: 13px 16px;
  border-radius: 12px;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid transparent;
  user-select: none;

  // theme
  &.primary {
    background: var(--color-primary);
    color: var(--color-white-1);
    &:enabled:hover {
      background: var(--color-blue-6);
    }
    &:enabled:active {
      background: var(--color-primary);
    }
    &:disabled {
      ${ButtonDisabledStyle}
    }
    &-ol {
      border: 1px solid var(--color-primary);
      color: var(--color-primary);
      background: none;
      &:disabled {
        ${ButtonOutlineDisabledStyle}
      }
    }
  }
  &.warning {
    background: var(--color-warning);
    color: var(--color-white-1);
    &:enabled:hover {
      background: var(--color-green-6);
    }
    &:enabled:active {
      background: var(--color-warning);
    }
    &:disabled {
      ${ButtonDisabledStyle}
    }
    &-ol {
      border: 1px solid var(--color-warning);
      color: var(--color-warning);
      background: none;
      &:disabled {
        ${ButtonOutlineDisabledStyle}
      }
    }
  }
  &.danger {
    background: var(--color-danger);
    color: var(--color-white-1);
    &:enabled:hover {
      background: var(--color-pink-6);
    }
    &:enabled:active {
      background: var(--color-danger);
    }
    &:disabled {
      ${ButtonDisabledStyle}
    }
    &-ol {
      border: 1px solid var(--color-danger);
      color: var(--color-danger);
      background: none;
      &:disabled {
        ${ButtonOutlineDisabledStyle}
      }
    }
  }
`;
