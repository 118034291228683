import { createSlice } from '@reduxjs/toolkit';

export const userInitialInfo = {
  userList: [],
  userInfo: {
    id: '',
    name: '',
    email: '',
    password: '',
    phoneAreaCode: '',
    phoneNum: '',
    companyName: '',
    companyPosition: '',
    companyPhoneAreaCode: '',
    companyPhoneNum: '',
    supplier: '',
    isPremium: false,
    isAdmin: false,
    createdAt: 0,
    updatedAt: 0
  }
};

export const userSlice = createSlice({
  name: 'USER',
  initialState: userInitialInfo,
  reducers: {
    setUserList: (state, action) => {
      state.userList = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    }
  }
});

export const { setUserList, setUserInfo } = userSlice.actions;
export default userSlice.reducer;
