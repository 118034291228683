import React from 'react';
import styled from 'styled-components';

function MainTemp() {
  return (
    <Wrap>
      <h3>웹 페이지 - 메인</h3>
    </Wrap>
  );
}
export default MainTemp;

const Wrap = styled.div``;
