import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { NewsTable, NewsDetail, NewsWrite } from '@/components/organisms';
import { useDataCrud } from '@/hooks';

export default function NewsTemp() {
  const currPageInfoRef = useRef({
    currPage: 'news'
  });
  const currPageInfo = currPageInfoRef.current;
  const { mode, dataId } = useParams();
  const { deleteData } = useDataCrud(currPageInfo);

  function deleteDataCB(id) {
    deleteData(currPageInfo, id);
  }

  return (
    <>
      {!dataId && !mode ? (
        <NewsTable pageInfo={currPageInfo} deleteDataCB={deleteDataCB} />
      ) : mode === 'detail' ? (
        <NewsDetail pageInfo={currPageInfo} deleteDataCB={deleteDataCB} />
      ) : mode === 'write' ? (
        <NewsWrite pageInfo={currPageInfo} deleteDataCB={deleteDataCB} />
      ) : null}
    </>
  );
}
