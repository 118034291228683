import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { CareerTable, CareerDetail } from '@/components/organisms';
import { useDataCrud } from '@/hooks';

export default function CareerTemp() {
  const currPageInfoRef = useRef({
    currPage: 'career'
  });
  const currPageInfo = currPageInfoRef.current;
  const { mode, dataId } = useParams();
  const { deleteData } = useDataCrud(currPageInfo);

  function deleteDataCB(id) {
    deleteData(
      currPageInfo,
      id,
      false,
      {
        object: '지원을',
        type: '삭제'
      },
      false,
      true
    );
  }

  return (
    <>
      {!dataId && !mode ? (
        <CareerTable pageInfo={currPageInfo} deleteDataCB={deleteDataCB} />
      ) : mode === 'detail' ? (
        <CareerDetail pageInfo={currPageInfo} deleteDataCB={deleteDataCB} />
      ) : null}
    </>
  );
}
