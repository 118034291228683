import React from 'react';
import styled from 'styled-components';
import { SignInForm } from '@/components/organisms';

function SignInTemp() {
  return (
    <Wrap>
      <SignInForm />
    </Wrap>
  );
}
export default SignInTemp;

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;
