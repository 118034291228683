import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * - 버튼을 그룹화하는 경우 사용합니다.
 */
export default function ButtonGroupComp({ children, margin = '30px 0 0 0' }) {
  return <ButtonGroup margin={margin}>{children}</ButtonGroup>;
}

//* props
ButtonGroupComp.propTypes = {
  /** Button 컴포넌트 */
  children: PropTypes.node,
  /** css : margin */
  margin: PropTypes.string
};

//* style
const ButtonGroup = styled.div`
  display: flex;
  margin: ${props => props.margin};
  justify-content: center;
  button {
    margin-right: 10px;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;
