import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Section } from '@/components/atoms';
import { Loading } from '@/components/molecules';
import { Header, Sidebar } from '@/components/organisms';
import {
  Main,
  SignIn,
  Blog,
  News,
  Contact,
  SiteInfo,
  User,
  Career,
  CareerCategory,
  TrendReport
} from '@/page';
import GlobalStyle from '@/static/style/global';
import { fireAuth, firestore } from '@/firebaseInit';
import { setAdminInfo } from '@/shared/redux/auth';

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { isLoading } = useSelector(state => state.common);
  const isSignInPage = window.location.pathname === '/signIn';

  // login check
  useEffect(() => {
    if (!isSignInPage) {
      fireAuth.onAuthStateChanged(async user => {
        if (user) {
          const userInfo = await firestore
            .collection('user')
            .doc(user.uid)
            .get()
            .then(res => res.data());

          dispatch(setAdminInfo(userInfo));
        } else {
          toast['error'](`로그인이 필요합니다.`);
          history.push('/signIn');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isSignInPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <GlobalStyle />
      <Wrap id='wrap'>
        {!isSignInPage && <Sidebar />}
        <SectionWrap isSignInPage={isSignInPage}>
          <Section isFull={isSignInPage}>
            <Switch>
              <Route exact path='/'>
                <Header />
                <Main />
              </Route>
              <Route path='/blog/:mode?/:dataId?'>
                <Header />
                <Blog />
              </Route>
              <Route path='/news/:mode?/:dataId?'>
                <Header />
                <News />
              </Route>
              <Route path='/contact/:mode?/:dataId?'>
                <Header />
                <Contact />
              </Route>
              <Route path='/user/:mode?/:dataId?'>
                <Header />
                <User />
              </Route>
              <Route path='/career/:mode?/:dataId?'>
                <Header />
                <Career />
              </Route>
              <Route path='/careerCategory/:mode?/:dataId?'>
                <Header />
                <CareerCategory />
              </Route>
              <Route path='/premium_trendReport'>
                <Header />
                <TrendReport />
              </Route>
              {/* <Route path='/siteInfo'>
                <Header />
                <SiteInfo />
              </Route> */}
              <Route path='/signIn' component={SignIn} />
            </Switch>
          </Section>
        </SectionWrap>
        <Loading isShow={isLoading} />
        <ToastContainer
          position='bottom-right'
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          draggable={false}
        />
      </Wrap>
    </>
  );
}
export default App;

const Wrap = styled.div`
  display: flex;
  min-height: 100vh;
  position: relative;
`;

const SectionStyle = {
  default: css`
    left: var(--size-sidebar-width);
    width: calc(100% - var(--size-sidebar-width));
    padding-top: calc(var(--size-header-height));
  `,
  full: css`
    left: 0;
    width: 100%;
  `
};

const SectionWrap = styled.div`
  background: var(--color-main-bg);
  flex: 1 0 auto;
  position: absolute;
  top: 0;
  ${({ isSignInPage }) => (isSignInPage ? SectionStyle.full : SectionStyle.default)}
  min-height: 100vh;
  box-sizing: border-box;
`;
