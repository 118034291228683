import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * - 리스트에 사용되는 List 컴포넌트입니다.
 * - `ul` 태그를 사용하였습니다.
 */
export default function ListComp(props) {
  const { children } = props;
  return <List {...props}>{children}</List>;
}

//* props
ListComp.propTypes = {
  /** 클래스 명 */
  className: PropTypes.string,
  /** css : display */
  display: PropTypes.string,
  /** css : align-items */
  align: PropTypes.string,
  /** css : justify-content */
  justify: PropTypes.string,
  /** css : flex-direction */
  direction: PropTypes.string,
  /** Item 컴포넌트 */
  children: PropTypes.node
};

ListComp.defaultProps = {
  display: 'block'
};

//* style
const List = styled.ul`
  display: ${props => props.display};
  align-items: ${props => props.align};
  justify-content: ${props => props.justify};
  flex-direction: ${props => props.direction};
`;
