import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Table } from '@/components/molecules';
import { postValueList, tableButtonList } from '@/shared/commonData';
import { setPostList, postInitialInfo } from '@/shared/redux/post';
import { setIsLoading } from '@/shared/redux/common';
import { useDataCrud } from '@/hooks';

export default function BlogTableComp({ pageInfo, deleteDataCB }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { postList } = useSelector(state => state.post);
  const { currPage } = pageInfo;
  const { getDataList } = useDataCrud();
  const tableValueList = [...postValueList, ...tableButtonList];

  // 리스트 데이터
  useEffect(() => {
    (async () => {
      const listData = await getDataList(pageInfo);
      dispatch(setPostList(listData));
      dispatch(setIsLoading(false));
    })();

    return () => {
      dispatch(setPostList(postInitialInfo.postList));
    };
  }, [dispatch, getDataList, pageInfo, location]);

  // 테이블 버튼 클릭
  const handleClickBtn = useCallback(
    (dataId, btnId) => {
      const btnTypeInfo = {
        detailBtn: () => history.push(`/${currPage}/detail/${dataId}`),
        modifyBtn: () =>
          history.push({
            pathname: `/${currPage}/write/${dataId}`
          }),
        deleteBtn: () => deleteDataCB(dataId)
      };
      btnTypeInfo[btnId]();
    },
    [currPage, deleteDataCB, history]
  );

  return (
    <Table
      titleList={tableValueList}
      totalCnt={postList.length}
      currList={postList}
      onClickBtn={handleClickBtn}
      submitPage='blog'
    />
  );
}
