import { createSlice } from '@reduxjs/toolkit';

export const postInitialInfo = {
  postList: [],
  postInfo: {
    id: '',
    title: '',
    content: '',
    thumbnail: '',
    createdAt: 0,
    updatedAt: 0
  }
};

export const postSlice = createSlice({
  name: 'POST',
  initialState: postInitialInfo,
  reducers: {
    setPostList: (state, action) => {
      state.postList = action.payload;
    },
    setPostInfo: (state, action) => {
      state.postInfo = action.payload;
    }
  }
});

export const { setPostList, setPostInfo } = postSlice.actions;
export default postSlice.reducer;
