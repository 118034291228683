import { createSlice } from '@reduxjs/toolkit';

export const contactInitialInfo = {
  contactList: [],
  contactInfo: {
    id: '',
    name: '',
    company: '',
    phone: '',
    email: '',
    site: '',
    service: [],
    content: '',
    createdAt: 0,
    updatedAt: 0
  }
};

export const contactSlice = createSlice({
  name: 'CONTACT',
  initialState: contactInitialInfo,
  reducers: {
    setContactList: (state, action) => {
      state.contactList = action.payload;
    },
    setContactInfo: (state, action) => {
      state.contactInfo = action.payload;
    }
  }
});

export const { setContactList, setContactInfo } = contactSlice.actions;
export default contactSlice.reducer;
