import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

/**
 * - section 영역 전체를 감싸는 Section 컴포넌트입니다.
 * - 'section` 태그를 사용하였습니다.
 */
export default function SectionComp({ className, children, isFull = false }) {
  return (
    <Section className={className} isFull={isFull}>
      {children}
    </Section>
  );
}

//* props
SectionComp.propTypes = {
  /** 클래스 명 */
  className: PropTypes.string,
  /** section 영역 항목 */
  children: PropTypes.node,
  /** section 높이값 100% 여부 */
  isFull: PropTypes.bool
};

//* style
const SectionStyle = {
  default: css`
    min-height: calc(100vh - var(--size-header-height));
    padding: 40px;
  `,
  full: css`
    min-height: 100vh;
  `
};

const Section = styled.section`
  box-sizing: border-box;
  ${({ isFull }) => (isFull ? SectionStyle.full : SectionStyle.default)};
`;
