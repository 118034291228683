import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Content, ContentTitle, List, ContentItem } from '@/components/atoms';
import { ButtonGroup, ContentTextItem } from '@/components/molecules';
import { useDataCrud } from '@/hooks';
import { timestampToDate } from '@/utils/common';
import { setCareerInfo, careerInitialInfo } from '@/shared/redux/career';
import { setIsLoading } from '@/shared/redux/common';

export default function CareerDetailComp({ pageInfo, deleteDataCB }) {
  const dispatch = useDispatch();
  const { dataId } = useParams();
  const { careerInfo } = useSelector(state => state.career);
  const { id, name, phone, category, parentCategory, addDocs, resume, createdAt } = careerInfo;
  const { getDataInfo } = useDataCrud();

  // 해당 id 데이터
  useEffect(() => {
    if (dataId) {
      (async () => {
        const infoData = await getDataInfo(pageInfo, dataId);
        const { categoryId } = infoData;
        const categoryInfo = await getDataInfo({ collection: 'careerCategory' }, categoryId);
        const { name, parentCategory } = categoryInfo;

        dispatch(
          setCareerInfo({
            ...infoData,
            category: name,
            parentCategory
          })
        );
        dispatch(setIsLoading(false));
      })();

      return () => {
        dispatch(setCareerInfo(careerInitialInfo.careerInfo));
      };
    }
  }, [getDataInfo, dataId, dispatch, pageInfo]);

  return (
    <>
      {id ? (
        <>
          <Content>
            <ContentTitle>지원 정보</ContentTitle>
            <List>
              <ContentTextItem labelTitle='이름' content={name} />
              <ContentTextItem labelTitle='휴대폰 번호' content={phone} />
              <ContentTextItem labelTitle='상위 카테고리' content={parentCategory} />
              <ContentTextItem labelTitle='카테고리' content={category} />
              <ContentItem labelTitle='이력서'>
                <ContentDlLink href={resume} rel='noreferrer' target='_blank' download>
                  다운로드
                </ContentDlLink>
              </ContentItem>
              {addDocs && (
                <ContentItem labelTitle='추가 서류'>
                  <ContentDlLink href={addDocs} rel='noreferrer' target='_blank' download>
                    다운로드
                  </ContentDlLink>
                </ContentItem>
              )}
              <ContentTextItem labelTitle='등록일' content={timestampToDate(createdAt)} />
            </List>
          </Content>
          <ButtonGroup>
            <Button theme='danger' onClickCB={() => deleteDataCB(id)}>
              삭제
            </Button>
          </ButtonGroup>
        </>
      ) : null}
    </>
  );
}

//* style
const ContentDlLink = styled.a`
  border-radius: 5px;
  font-size: 14px;
  padding: 5px 15px;
  background: var(--color-warning);
  color: var(--color-white-1);
  &:hover {
    opacity: 0.9;
  }
`;
