import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

export default function ImageComp({ className, src, alt, url, size = 'auto', color }) {
  return (
    <>
      {src ? (
        <Picture className={className}>
          <Img src={src} alt={alt} />
        </Picture>
      ) : (
        <SvgImg className={`${className} svg-img`} url={url} color={color} size={size} />
      )}
    </>
  );
}

//* props
ImageComp.propTypes = {
  /** 클래스 명 */
  className: PropTypes.string,
  /** image src */
  src: PropTypes.string,
  /** image alt */
  alt: PropTypes.string,
  /** background url */
  url: PropTypes.string,
  /** background size */
  size: PropTypes.string,
  /** background color */
  color: PropTypes.string
};

//* style
const ImgStyle = css`
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
`;
const Picture = styled.picture`
  ${ImgStyle}
  display:inline-block;
`;
const Img = styled.img`
  ${ImgStyle}
`;
const SvgImg = styled.div`
  ${ImgStyle}
  mask: ${({ url }) => `url(${url}) no-repeat`};
  background-color: ${({ color }) => color};
  background-size: ${({ size }) => size};
`;
