import React from 'react';
import PropTypes from 'prop-types';

/**
 * - `List` 컴포넌트에서 사용되는 리스트 아이템입니다.
 * - `li` 태그를 사용하였습니다.
 */
function ItemComp({ className, children, onClickCB }) {
  return (
    <li className={className} onClick={onClickCB}>
      {children}
    </li>
  );
}
export default React.memo(ItemComp);

//* props
ItemComp.propTypes = {
  /** 클래스 명 */
  className: PropTypes.string,
  /** 리스트 아이템 항목 */
  children: PropTypes.node,
  /** 리스트 아이템 클릭 이벤트 */
  onClickCB: PropTypes.func
};
