import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import shortid from 'shortid';
import { Button, Content, ContentTitle, Input, Label, NoImage } from '@/components/atoms';
import { ButtonGroup, Wysiwyg } from '@/components/molecules';
import { useDataCrud, useWysiwyg } from '@/hooks';
import { TitleInput, ThumbnailBox, Thumbnail } from '@/components/templates/Common/Crud/Post/style';
import { setPostInfo, postInitialInfo } from '@/shared/redux/post';
import { setIsLoading } from '@/shared/redux/common';
import { imgCompression } from '@/utils/common';

export default function BlogWriteComp({ pageInfo, deleteDataCB }) {
  const dispatch = useDispatch();
  const params = useParams();
  const queryDataId = params.dataId;
  const { dataId } = params;
  const { writeWysiwig } = useWysiwyg(pageInfo);
  const { postInfo } = useSelector(state => state.post);
  const { id } = postInfo;
  const { getDataInfo, writeData, modifyData, uploadImgCleanUp, uploadImg } = useDataCrud();
  const wysiwygFileRef = useRef([]);
  const [newDataTitle, setNewDataTitle] = useState('');
  const [newDataContent, setNewDataContent] = useState('');
  const [newPostThumbnail, setNewPostThumbnail] = useState({
    file: null,
    url: ''
  });
  const [isRemoveThumbnail, setIsRemoveThumbnail] = useState(false);
  const inputRef = useRef(null);

  // 데이터
  useEffect(() => {
    if (dataId) {
      (async () => {
        const infoData = await getDataInfo(pageInfo, dataId);
        const { title, content, thumbnail = '' } = infoData;

        setNewDataTitle(title);
        setNewDataContent(content);
        setNewPostThumbnail({
          ...newPostThumbnail,
          url: thumbnail
        });
        dispatch(setPostInfo(infoData));
        dispatch(setIsLoading(false));
      })();

      return () => {
        setNewDataTitle('');
        setNewDataContent('');
        setNewPostThumbnail({
          file: null,
          url: ''
        });
        dispatch(setPostInfo(postInitialInfo.postInfo));
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataInfo, dataId, dispatch, pageInfo]);

  function handleChangeEditor(content, delta, source, editor) {
    setNewDataContent(editor.getHTML());
  }

  function handleChangeValue(e) {
    const { value } = e.target;
    setNewDataTitle(value);
  }

  async function writeBlog(type = '') {
    const info = {
      type,
      title: newDataTitle,
      content: newDataContent,
      titleRef: inputRef,
      wysiwygFileRef
    };

    const resultDataInfo = await writeWysiwig(info);

    // 썸네일 이미지 저장 및 cleanUp
    let currThumbnail = newPostThumbnail.url;
    if (type && postInfo.thumbnail && (isRemoveThumbnail || newPostThumbnail.file)) {
      await uploadImgCleanUp(pageInfo, dataId, null, true);
      currThumbnail = '';
    }
    if (newPostThumbnail.file) {
      currThumbnail = await uploadImg(
        pageInfo,
        dataId,
        shortid.generate(),
        newPostThumbnail.file,
        true
      );
    }

    const { id, content } = resultDataInfo;

    const resultData = {
      id,
      title: newDataTitle,
      content,
      thumbnail: currThumbnail
    };

    type ? modifyData(pageInfo, id, resultData) : writeData(pageInfo, id, resultData);
  }

  async function handleChangeThumbnail(e) {
    const file = e.target.files[0];
    const { compressedFile, compressedDataUrl } = await imgCompression(file, 500);

    setNewPostThumbnail({ file: compressedFile, url: compressedDataUrl });
  }

  return (
    <div>
      <Content>
        <ContentTitle isLine={false}>
          <TitleInput
            type='text'
            name='title'
            value={newDataTitle}
            onChange={handleChangeValue}
            ref={inputRef}
            placeholder='제목을 입력해주세요.'
          />
        </ContentTitle>
        {(!queryDataId || newDataTitle) && (
          <Wysiwyg ref={wysiwygFileRef} onChange={handleChangeEditor} value={newDataContent} />
        )}
        <ThumbnailBox>
          {newPostThumbnail.url ? (
            <Thumbnail
              src={`${newPostThumbnail.url}`}
              alt={newPostThumbnail.url && 'thumbnail-img'}
            />
          ) : (
            <NoImage value='thumbnail' />
          )}
          <ThumbnailActionBox>
            <input type='file' name='thumbnail' onChange={handleChangeThumbnail} />
            <ThumbnailCheckBox>
              <Label htmlFor='removeThumbnail'>썸네일 제거</Label>
              <CheckBox
                type='checkbox'
                id='removeThumbnail'
                onChange={() => setIsRemoveThumbnail(!isRemoveThumbnail)}
              />
            </ThumbnailCheckBox>
          </ThumbnailActionBox>
        </ThumbnailBox>
      </Content>
      <ButtonGroup>
        {queryDataId ? (
          <>
            <Button theme='warning' onClickCB={() => writeBlog('modify')}>
              수정
            </Button>
            <Button theme='danger' onClickCB={() => deleteDataCB(id)}>
              삭제
            </Button>
          </>
        ) : (
          <Button theme='primary' onClickCB={() => writeBlog()}>
            등록
          </Button>
        )}
      </ButtonGroup>
    </div>
  );
}

//* style
const ThumbnailActionBox = styled.div`
  margin-left: 15px;
`;
const ThumbnailCheckBox = styled.div`
  margin-top: 10px;
`;
const CheckBox = styled(Input)`
  margin-left: 10px;
`;
