import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  CareerCategoryTable,
  CareerCategoryDetail,
  CareerCategoryWrite
} from '@/components/organisms';
import { useDataCrud } from '@/hooks';

export default function CareerCategoryTemp() {
  const currPageInfoRef = useRef({
    currPage: 'careerCategory'
  });
  const currPageInfo = currPageInfoRef.current;
  const { mode, dataId } = useParams();
  const { deleteData } = useDataCrud(currPageInfo);

  function deleteDataCB(id) {
    deleteData(currPageInfo, id, false, {
      object: '카테고리를',
      type: '삭제'
    });
  }

  return (
    <>
      {!dataId && !mode ? (
        <CareerCategoryTable pageInfo={currPageInfo} deleteDataCB={deleteDataCB} />
      ) : mode === 'detail' ? (
        <CareerCategoryDetail pageInfo={currPageInfo} deleteDataCB={deleteDataCB} />
      ) : mode === 'write' ? (
        <CareerCategoryWrite pageInfo={currPageInfo} deleteDataCB={deleteDataCB} />
      ) : null}
    </>
  );
}
