import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function InputComp(props, ref) {
  const { type = 'text', width = 'auto' } = props;
  const inputRef = useRef(null);
  useImperativeHandle(ref, () => inputRef.current);

  return <Input {...props} ref={inputRef} type={type} width={width} />;
}
export default React.memo(forwardRef(InputComp));

//* props
InputComp.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  width: PropTypes.string
};

//* style
const Input = styled.input`
  padding: 15px 16px;
  box-sizing: border-box;
  border-radius: 12px;
  border: 0;
  width: ${({ width }) => width};
  &:read-only {
    background: #efeeee;
  }
`;
