import { css } from 'styled-components';

export const resetStyle = css`
  * {
    margin: 0;
    padding: 0;
  }

  html,
  body {
    font-size: 16px;
  }

  ul,
  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }
`;
