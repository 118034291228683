import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Table, SearchInput, TableTopArea } from '@/components/molecules';
import { CareerCategorySelect } from '@/components/organisms';
import { careerCategoryValueList, tableButtonList } from '@/shared/commonData';
import { useDataCrud } from '@/hooks';
import { setCareerCategoryList, careerInitialInfo } from '@/shared/redux/career';
import { setIsLoading } from '@/shared/redux/common';

export default function CareerCategoryTableComp({ pageInfo, deleteDataCB }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { currPage } = pageInfo;
  const { careerCategoryList } = useSelector(state => state.career);
  const { getDataList } = useDataCrud(currPage);
  const [filterCategoryList, setFilterCategoryList] = useState([]);
  const tableValueList = [
    ...careerCategoryValueList.filter(item => !item.isNotList),
    ...tableButtonList
  ];

  // 리스트 데이터
  useEffect(() => {
    (async () => {
      const listData = await getDataList(pageInfo);
      const categoryListData = await getDataList({ collection: 'careerCategory' });

      setFilterCategoryList(listData);
      dispatch(setCareerCategoryList(categoryListData));
      dispatch(setIsLoading(false));
    })();

    return () => {
      setFilterCategoryList([]);
      dispatch(setCareerCategoryList(careerInitialInfo.careerCategoryList));
    };
  }, [dispatch, getDataList, pageInfo, location]);

  // 테이블 버튼 클릭
  const handleClickBtn = useCallback(
    (dataId, btnId) => {
      const btnTypeInfo = {
        detailBtn: () => history.push(`/${currPage}/detail/${dataId}`),
        modifyBtn: () =>
          history.push({
            pathname: `/${currPage}/write/${dataId}`
          }),
        deleteBtn: () => deleteDataCB(dataId)
      };
      btnTypeInfo[btnId]();
    },
    [currPage, deleteDataCB, history]
  );

  return (
    <>
      <TableTopArea>
        <CareerCategorySelect
          pageInfo={pageInfo}
          dataList={careerCategoryList}
          setState={setFilterCategoryList}
        />
        <SearchInput queryKey='name' searchValue='카테고리명을' inputWidth='230px' />
      </TableTopArea>
      <Table
        titleList={tableValueList}
        totalCnt={filterCategoryList.length}
        currList={filterCategoryList}
        submitPage={pageInfo.currPage}
        onClickBtn={handleClickBtn}
      />
    </>
  );
}
