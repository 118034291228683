import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CareerTemp } from '@/components/templates';
import { setPageDepthList } from '@/shared/redux/common';

export default function CareerPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const { dataId, mode } = params;

  // pageDepth
  useEffect(() => {
    dispatch(
      setPageDepthList({
        pageName: 'career',
        depthPageName: '지원 리스트',
        mode,
        dataId
      })
    );
  }, [dispatch, mode, dataId]);

  return <CareerTemp />;
}
