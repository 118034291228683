import { css, createGlobalStyle } from 'styled-components';
import { resetStyle } from './reset';

// 스토리북 마크다운 스타일
const MarkdownStyle = css`
  // 리스트 disc
  .sbdocs {
    list-style: disc !important;
  }
`;

const GlobalStyle = createGlobalStyle`
  ${resetStyle}
  ${MarkdownStyle}

  html,body{
    background:var(--color-bg);
  }

  #wrap{
    *{
      font-family: var(--font-family);
    }

    input:not([type='file']),textarea{
      font-size: var(--font-size-4);
      color: var(--color-black-2);
      font-weight: 500;
      box-shadow: 0px 0px 2px transparent;
      &:focus {
        outline: none;
        transition:box-shadow 0.2s;
        box-shadow: 0px 0px 2px var(--color-blue-5);
      }
      &::placeholder {
        color: var(--color-gray-3);
      }
    }

    .animation-rotation{
      @keyframes rotation{
        from{
          transform: rotate(0deg);
        }
        to{
          transform: rotate(360deg);
        }
      }
    }
  }

  // color
  :root{
    --color-white-1 :#FFFFFF;
    --color-white-2 :#F7F9FA;
    
    --color-gray-1:#F1F3F4;
    --color-gray-2:#E3E5E6;
    --color-gray-3:#C6CACC;
    --color-gray-4:#919699;
    --color-gray-5:#5E6366;

    --color-black-1 : #3E4345;
    --color-black-2 : #000000;

    --color-blue-1 : #CCECFF;
    --color-blue-2 : #99DAFF;
    --color-blue-3 : #00A2FF;
    --color-blue-4 : #0082CC;
    --color-blue-5: #0072B3;
    --color-blue-5-rgb: 0, 114, 179;
    --color-blue-6: #005180;
    --color-blue-7: #002033;

    --color-orange-1 : #FFEBDF;
    --color-orange-2 : #FECCAD;
    --color-orange-3 : #FD9D61;
    --color-orange-4 : #FC7E2F;
    --color-orange-5: #FC6E16;
    --color-orange-6: #DB5603;
    --color-orange-7: #903902;

    --color-green-1 : #C6FFF4;
    --color-green-2 : #8FFCE7;
    --color-green-3 : #00E0B5;
    --color-green-4 : #00AD8C;
    --color-green-5: #007A63;
    --color-green-6: #00604E;
    --color-green-7: #002D25;

    --color-pink-1 : #FDDAE5;
    --color-pink-2 : #F9ABC3;
    --color-pink-3 : #F67BA1;
    --color-pink-4 : #F34B7F;
    --color-pink-5: #F1336E;
    --color-pink-6: #CA0E48;
    --color-pink-7: #53061D;

    --color-primary : var(--color-blue-5);
    --color-danger : var(--color-pink-5);
    --color-warning : var(--color-green-5);
    --color-disabled : var(--color-gray-3);

    --color-bg: #F6F9FC;
    --color-sidebar: #002033;
    --color-input-bg : #F6FAFC;
  }

  // size
  :root{
    --size-sidebar-width : 250px;
    --size-section-width : calc(100% - var(--size-sidebar-width));
    --size-header-height : 95px;
    --size-content-full-height : calc(100vh - (var(--size-header-height) + 40px + 40px));
  }

  // font
  :root {
    --font-family : 'Noto Sans', sans-serif;

    --font-size-1 : 24px; 
    --font-size-2 : 20px; 
    --font-size-3 : 18px; 
    --font-size-4 : 16px; 
  }
`;

export default GlobalStyle;
