import { createSlice } from '@reduxjs/toolkit';

export const siteInfoInitialInfo = {
  companyName: '',
  companyAddress: '',
  companyEmail: '',
  ceoName: '',
  companyRN: ''
};

export const siteInfoSlice = createSlice({
  name: 'SITE_INFO',
  initialState: siteInfoInitialInfo,
  reducers: {
    setSiteInfo: (state, action) => {
      state.postList = action.payload;
    }
  }
});

export const { setSiteInfo } = siteInfoSlice.actions;
export default siteInfoSlice.reducer;
