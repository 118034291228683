import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import shortid from 'shortid';
import queryString from 'query-string';
import { useDataCrud } from '@/hooks';
import { setIsLoading } from '@/shared/redux/common';

export default function useWysiwig(pageInfo) {
  const params = useParams();
  const queryDataId = params.dataId;
  const dispatch = useDispatch();
  const { uploadImg, uploadImgCleanUp } = useDataCrud();

  const writeWysiwig = useCallback(
    async info => {
      const { type, title, content, titleRef, wysiwygFileRef } = info;

      if (!title) {
        alert('제목을 입력해주세요.');
        titleRef.current.focus();
        return false;
      }

      const contentValidation = content.replace(/<(.|\n)*?>/g, '').trim().length;
      if (!content || !contentValidation) {
        alert('내용을 입력해주세요.');
        return false;
      }

      dispatch(setIsLoading(true));

      const currDataId = queryDataId ? queryDataId : shortid.generate();
      const isModify = type === 'modify';

      // 문자열로 된 에디터의 컨텐츠들을 div.content-wrap 으로 wrap 된 html 로 parsing
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(
        `<div id="content-wrap">${content}</div>`,
        'text/html'
      );

      // 에디터에 존재하는 모든 이미지 정보
      const imgEle = htmlDoc.getElementsByTagName('img');

      //? 에디터에 이미지가 있는 경우
      if (imgEle.length) {
        const imgSrc = Array.from(imgEle).map(img => img.src);

        // 모든 이미지 정보 중에서 새로 등록한 이미지만 필터링
        const newImgList = wysiwygFileRef.current.filter(file => imgSrc.includes(file.result));

        // 새로 추가하는 이미지의 인덱스값을 Get (수정)
        const newImgIdxList = imgSrc
          .map((img, idx) => (!new URL(img).host ? idx : ''))
          .filter(val => val !== '');

        // 새로 등록한 이미지를 firestorage 에 저장
        for (let i = 0; i < newImgList.length; i++) {
          const imgId = shortid.generate();

          // 에디터에 새로 추가한 이미지의 위치 인덱스값 (수정)
          const newImgIdx = newImgIdxList[i];

          // firestorage 에 이미지 저장 + url 가져오기
          const currImageUrl = await uploadImg(pageInfo, currDataId, imgId, newImgList[i]);

          // 에디터에 있는 이미지의 경로를 저장한 이미지 url 로 변경
          isModify ? (imgEle[newImgIdx].src = currImageUrl) : (imgEle[i].src = currImageUrl);
        }

        // 사용하지 않는 이미지를 스토리지에서 제거 (수정)
        if (isModify) {
          const imgIdArr = Array.from(imgEle).map(img => queryString.parse(img.src).imgId);
          uploadImgCleanUp(pageInfo, currDataId, imgIdArr);
        }
      }

      return {
        id: currDataId,
        content: htmlDoc.querySelector('#content-wrap').outerHTML
      };
    },
    [dispatch, pageInfo, queryDataId, uploadImg, uploadImgCleanUp]
  );

  return { writeWysiwig };
}
