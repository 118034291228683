import { createSlice } from '@reduxjs/toolkit';

export const careerInitialInfo = {
  careerCategoryList: [],
  careerCategoryInfo: {
    id: '',
    name: '',
    parentCategory: '',
    description: '',
    shortDescription: '',
    qualification: '',
    employment: '',
    submission: '',
    createdAt: 0,
    updatedAt: 0
  },
  careerList: [],
  careerInfo: {
    id: '',
    name: '',
    phone: '',
    category: '',
    parentCategory: '',
    addDocs: '',
    resume: '',
    createdAt: 0,
    updatedAt: 0
  }
};

export const careerSlice = createSlice({
  name: 'CAREER',
  initialState: careerInitialInfo,
  reducers: {
    setCareerCategoryList: (state, action) => {
      state.careerCategoryList = action.payload;
    },
    setCareerCategoryInfo: (state, action) => {
      state.careerCategoryInfo = action.payload;
    },
    setCareerList: (state, action) => {
      state.careerList = action.payload;
    },
    setCareerInfo: (state, action) => {
      state.careerInfo = action.payload;
    }
  }
});

export const {
  setCareerCategoryList,
  setCareerCategoryInfo,
  setCareerList,
  setCareerInfo
} = careerSlice.actions;
export default careerSlice.reducer;
