import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * - form 유효성 에러 텍스트 입니다.
 */
export default function FormErrorTextComp({ children }) {
  return <FormErrorText>{children}</FormErrorText>;
}

//* props
FormErrorTextComp.propTypes = {
  children: PropTypes.node
};

//* style
const FormErrorText = styled.p`
  color: var(--color-danger);
  font-size: 0.875rem;
  margin-top: 10px;
  text-align: left;
`;
