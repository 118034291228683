import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Input, TextArea, ContentItem } from '@/components/atoms';

/**
 * - 컨텐츠 항목을 등록, 수정하는 경우 사용되는 컴포넌트입니다.
 * - ContentItem 컴포넌트에 Input, TextArea 컴포넌트를 추가하였습니다.
 */
export default function ContentInputItemComp({
  className,
  labelTitle,
  labelWidth,
  name,
  type,
  value,
  onChange,
  isTextArea
}) {
  return (
    <ContentItem
      className={className}
      labelTitle={labelTitle}
      labelWidth={labelWidth}
      marginBottom='40px'
    >
      {isTextArea ? (
        <TextAreaComp name={name} value={value} onChange={onChange} />
      ) : (
        <InputComp name={name} value={value} type={type} onChange={onChange} />
      )}
    </ContentItem>
  );
}

//* props
ContentInputItemComp.propTypes = {
  /** 클래스 명 */
  className: PropTypes.string,
  /** 라벨 텍스트 */
  labelTitle: PropTypes.string,
  /** 라벨 넓이값 */
  labelWidth: PropTypes.string,
  /** input & textarea : name */
  name: PropTypes.string,
  /** input & textarea : type */
  type: PropTypes.string,
  /** input & textarea : value */
  value: PropTypes.string,
  /** input & textarea : onChange */
  onChange: PropTypes.func,
  /** textarea 사용 여부 */
  isTextArea: PropTypes.bool
};

//* style
const commonStyle = css`
  background: var(--color-input-bg);
`;
const InputComp = styled(Input)`
  ${commonStyle}
  display:block;
`;
const TextAreaComp = styled(TextArea)`
  ${commonStyle}
  display:inline-block;
  flex: 1 1 auto;
  min-width: auto;
  height: 150px;
`;
