import moment from 'moment';
import 'moment/locale/ko';
import { viewCountList } from '@/shared/commonData';
import imageCompression from 'browser-image-compression';
import queryString from 'query-string';
import crypto from 'crypto';

export function showError(errMessage) {
  console.error(`${errMessage} 중 에러가 발생하였습니다.`);
}

export const timestampToDate = (timestamp, format = 'YYYY/MM/DD HH:mm:ss') => {
  return moment.unix(timestamp).format(format);
};

export function getUrlQueryValue(location, queryKey, defaultValue = '') {
  if (!location.pathname && !queryKey) {
    showError('쿼리값을 가져오는');
    return false;
  }

  const query = new URLSearchParams(location.search);
  return query.get(queryKey) ? query.get(queryKey) : defaultValue;
}

export function createQueryString(info, search) {
  let query;

  if (search) {
    const searchObj = {
      ...queryString.parse(search),
      ...info
    };

    // eslint-disable-next-line no-unused-vars
    query = queryString.stringify(searchObj);
  } else {
    // eslint-disable-next-line no-unused-vars
    query = queryString.stringify(info);
  }

  return {
    search: query
  };
}

export function getCurrPageList(listData, location) {
  if (!listData) {
    showError('현재 페이지의 리스트를 가져오는');
    return false;
  }
  if (!location) {
    showError('location 값을 가져오는');
    return false;
  }

  const viewCount = Number(getUrlQueryValue(location, 'viewCount', viewCountList[0].value));
  const currPage = Number(getUrlQueryValue(location, 'page', 1));
  const lastIdx = currPage * viewCount;
  const firstIdx = lastIdx - viewCount;

  // currPageList
  return listData.slice(firstIdx, lastIdx);
}

export async function imgCompression(file, size = 1280) {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: size
  };
  const compressedFile = await imageCompression(file, options);
  const compressedDataUrl = await imageCompression.getDataUrlFromFile(compressedFile);

  return { compressedFile, compressedDataUrl };
}

export function convertValue(kind, value) {
  if (kind === 'timestamp') {
    return timestampToDate(value);
  }
  if (kind !== 'button' && (!value || !value.length)) {
    return '-';
  }
  if (kind === 'array') {
    return String(value).replaceAll(',', ', ');
  }

  if (kind === 'boolean') {
    return value ? 'o' : 'x';
  }

  return value;
}

export function encryptionPassword(password) {
  return crypto.createHash('sha512').update(password).digest('base64');
}

export function convertEnterToBrTag(value) {
  return value.replace(/\n/g, '<br>');
}

export function convertBrTagToEnter(value) {
  return value.replaceAll('<br>', '\r\n');
}
