import React, { useRef } from 'react';
import { TrendReportSetting } from '@/components/organisms';

export default function TrendReportTemp() {
  const currPageInfoRef = useRef({
    currPage: 'premium_trendReport',
    collection: 'premium'
  });
  const currPageInfo = currPageInfoRef.current;

  return <TrendReportSetting pageInfo={currPageInfo} />;
}
