import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { UserTable, UserDetail, UserWrite } from '@/components/organisms';
import { useDataCrud } from '@/hooks';

export default function UserTemp() {
  const currPageInfoRef = useRef({
    currPage: 'user'
  });
  const currPageInfo = currPageInfoRef.current;

  const { mode, dataId } = useParams();
  const { deleteDataSoft } = useDataCrud(currPageInfo);

  function deleteDataCB(id) {
    deleteDataSoft(currPageInfo, id, {
      object: '회원을',
      type: '탈퇴 처리'
    });
  }

  return (
    <>
      {!dataId && !mode ? (
        <UserTable pageInfo={currPageInfo} deleteDataCB={deleteDataCB} />
      ) : mode === 'detail' ? (
        <UserDetail pageInfo={currPageInfo} deleteDataCB={deleteDataCB} />
      ) : mode === 'write' ? (
        <UserWrite pageInfo={currPageInfo} deleteDataCB={deleteDataCB} />
      ) : null}
    </>
  );
}
