import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { List, Item } from '@/components/atoms';
import { useLocation } from 'react-router-dom';
import { getUrlQueryValue } from '@/utils/common';

export default function SelectComp({
  name,
  width = 'auto',
  className,
  optionList = [],
  initSelect = '',
  initTitle = '-------',
  onClick,
  speed = 0.25,
  isResetOption = true,
  isUpDepth = false
}) {
  const [isShow, setIsShow] = useState(false);
  const location = useLocation();
  const currOption = optionList.filter(v => v.value === initSelect)[0]?.text;
  const titleOption = getUrlQueryValue(location, name) || currOption ? currOption : initTitle;

  function onClickOption(value, text) {
    onClick(value, text);
    setIsShow(false);
  }

  useEffect(() => {
    function hideOptionList() {
      if (isShow) setIsShow(false);
    }

    document.addEventListener('click', hideOptionList);
    return () => {
      document.removeEventListener('click', hideOptionList);
    };
  }, [isShow]);

  return (
    <SelectBox width={width} className={`select ${className}`}>
      <SelectTitle onClick={() => setIsShow(!isShow)} className={`${isShow && 'on'}`} speed={speed}>
        <p>{titleOption ? titleOption : optionList[0]?.text}</p>
        <SelectArrow />
      </SelectTitle>
      {optionList.length ? (
        <Select className={isShow && 'on'} speed={speed} isUpDepth={isUpDepth}>
          {isResetOption && <Option onClickCB={() => onClickOption('')}>전체</Option>}
          {optionList.map((v, i) => (
            <Option value={v.value} key={i} onClickCB={() => onClickOption(v.value, v.text)}>
              {v.text}
            </Option>
          ))}
        </Select>
      ) : null}
    </SelectBox>
  );
}

//* props
SelectComp.propTypes = {
  /** 클래스 명 */
  className: PropTypes.string,
  /** select 명 */
  name: PropTypes.string,
  /** select 넓이값 */
  width: PropTypes.string,
  /** option 리스트 */
  optionList: PropTypes.array.isRequired,
  /** select 초기값 */
  initSelect: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  /** select 초기 타이틀 */
  initTitle: PropTypes.string,
  /** option 클릭 이벤트 */
  onClick: PropTypes.func.isRequired,
  /** select 트랜지션 속도값 */
  speed: PropTypes.number,
  /** select 초기화 option 여부 */
  isResetOption: PropTypes.bool,
  /** select option 리스트 상단 방향 여부 */
  isUpDepth: PropTypes.bool
};

//* style
const commonStyle = css`
  padding: 16px;
  box-sizing: border-box;
  border-radius: 15px;
  user-select: none;
  background: var(--color-white-1);
  transition: all ${({ speed }) => speed}s;
  width: 100%;
`;
const SelectBox = styled.div`
  position: relative;
  z-index: 10;
  min-width: 150px;
  width: ${({ width }) => width};
  font-weight: 400;
`;
const SelectTitle = styled.div`
  ${commonStyle};
  height: 50px;
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-gray-3);
  border: 1px solid var(--color-blue-5);
  background: var(--color-white-1);
  cursor: pointer;
  & > p {
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 75%;
  }
`;
const SelectArrow = styled.span`
  border: 1px solid var(--color-blue-5);
  border-bottom: 0;
  border-left: 0;
  transform: rotate(135deg);
  width: 10px;
  height: 10px;
  margin-top: -5px;
`;

const selectStyle = {
  up: css`
    bottom: calc(80%);
    &.on {
      opacity: 1;
      visibility: visible;
      bottom: calc(100% + 5px);
    }
  `,
  down: css`
    top: calc(80%);
    &.on {
      opacity: 1;
      visibility: visible;
      top: calc(100% + 5px);
    }
  `
};
const Select = styled(List)`
  ${commonStyle}
  width:auto;
  min-width: 100%;
  position: absolute;
  z-index: 5;
  background: var(--color-white-1);
  opacity: 0;
  visibility: hidden;
  border: 1px solid var(--color-blue-5);
  ${({ isUpDepth }) => (isUpDepth ? selectStyle.up : selectStyle.down)}
`;
const Option = styled(Item)`
  cursor: pointer;
  color: var(--color-black-2);
  padding: 8px;
`;
