import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Content, ContentTitle, List } from '@/components/atoms';
import { ButtonGroup, ContentTextItem } from '@/components/molecules';
import { useDataCrud } from '@/hooks';
import { timestampToDate } from '@/utils/common';
import { setUserInfo, userInitialInfo } from '@/shared/redux/user';
import { setIsLoading } from '@/shared/redux/common';
import { userSupplierInfo } from '@/shared/commonData';

export default function UserDetailComp({ pageInfo, deleteDataCB }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { dataId } = useParams();
  const { currPage } = pageInfo;
  const { userInfo } = useSelector(state => state.user);
  const {
    id,
    name,
    email,
    phoneNum,
    companyName,
    companyPosition,
    companyPhoneAreaCode,
    companyPhoneNum,
    supplier,
    isPremium,
    createdAt,
    updatedAt
  } = userInfo;
  const { getDataInfo } = useDataCrud();

  // 해당 id 데이터
  useEffect(() => {
    if (dataId) {
      (async () => {
        const infoData = await getDataInfo(pageInfo, dataId);
        dispatch(setUserInfo(infoData));
        dispatch(setIsLoading(false));
      })();

      return () => {
        dispatch(setUserInfo(userInitialInfo.userInfo));
      };
    }
  }, [getDataInfo, dataId, dispatch, pageInfo]);

  function removeHyphen(value) {
    return String(value).replaceAll('-', '');
  }

  return (
    <>
      {id ? (
        <>
          <Content>
            <ContentTitle>유저 정보</ContentTitle>
            <List>
              <ContentTextItem labelTitle='이름' content={name} />
              <ContentTextItem labelTitle='이메일' content={email} />
              <ContentTextItem labelTitle='휴대폰 번호' content={removeHyphen(phoneNum)} />
              <ContentTextItem labelTitle='회사명' content={companyName} />
              <ContentTextItem
                labelTitle='회사 전화번호'
                content={removeHyphen(companyPhoneAreaCode + companyPhoneNum)}
              />
              <ContentTextItem labelTitle='회사 직급' content={companyPosition} />
              <ContentTextItem labelTitle='로그인 유형' content={userSupplierInfo[supplier]} />
              <ContentTextItem labelTitle='프리미엄' content={isPremium ? 'o' : 'x'} />
              <ContentTextItem labelTitle='가입일' content={timestampToDate(createdAt)} />
              <ContentTextItem labelTitle='수정일' content={timestampToDate(updatedAt)} />
            </List>
          </Content>
          <ButtonGroup>
            <Button theme='warning' onClickCB={() => history.push(`/${currPage}/write/${id}`)}>
              수정
            </Button>
            <Button theme='danger' onClickCB={() => deleteDataCB(id)}>
              삭제
            </Button>
          </ButtonGroup>
        </>
      ) : null}
    </>
  );
}
