import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export default function TextAreaComp(props) {
  return <TextArea {...props} />;
}

//* props
TextAreaComp.propTypes = {
  /** 클래스 명 */
  className: PropTypes.string,
  /** 기본값 */
  defaultValue: PropTypes.string,
  /** placeholder */
  placeholder: PropTypes.string,
  /** onChange 이벤트 */
  onChange: PropTypes.func
};

//* style
const TextArea = styled.textarea`
  min-width: 100%;
  padding: 20px;
  box-sizing: border-box;
  resize: none;
  border: 0;
  border-radius: 12px;
`;
