import styled from 'styled-components';
import { Image, Input } from '@/components/atoms';

export const ThumbnailBox = styled.div`
  padding-top: 20px;
  margin-top: 20px;
  display: flex;
`;
export const Thumbnail = styled(Image)`
  max-width: 100px;
`;
export const TitleInput = styled(Input)`
  width: 100%;
  background: var(--color-input-bg);
`;
