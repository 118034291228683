import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ChevronRight } from '@material-ui/icons';

/**
 * - 현재 페이지를 확인할 수 있습니다.
 */
export default function PageDepthComp({ depthList = [] }) {
  return (
    <PageDepth>
      {depthList.map((item, idx) => (
        <React.Fragment key={idx}>
          <PageDepthItem>{item}</PageDepthItem>
          {idx !== depthList.length - 1 && (
            <PageDepthArrow>
              <ChevronRight />
            </PageDepthArrow>
          )}
        </React.Fragment>
      ))}
    </PageDepth>
  );
}

//* props
PageDepthComp.propTypes = {
  /** depth 리스트 */
  depthList: PropTypes.array
};

//* style
const PageDepth = styled.div`
  display: flex;
  align-items: center;
`;
const PageDepthItem = styled.span`
  font-size: var(--font-size-4);
  display: inline-block;
  user-select: none;
`;
const PageDepthArrow = styled.span`
  margin: 0 8px;
  display: inline-flex;
  align-items: center;
`;
