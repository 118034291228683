import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ContactTable, ContactDetail } from '@/components/organisms';
import { useDataCrud } from '@/hooks';

export default function ContactTemp() {
  const currPageInfoRef = useRef({
    currPage: 'contact'
  });
  const currPageInfo = currPageInfoRef.current;
  const { mode, dataId } = useParams();
  const { deleteData } = useDataCrud(currPageInfo);

  function deleteDataCB(id) {
    deleteData(currPageInfo, id, false, {
      object: '문의를',
      type: '삭제'
    });
  }

  return (
    <>
      {!dataId && !mode ? (
        <ContactTable pageInfo={currPageInfo} deleteDataCB={deleteDataCB} />
      ) : mode === 'detail' ? (
        <ContactDetail pageInfo={currPageInfo} deleteDataCB={deleteDataCB} />
      ) : null}
    </>
  );
}
