//* api
export const apiUrl = 'http://localhost:2000/api';

//* header menu
export const sidebarMenuList = [
  // {
  //   title: '대시보드',
  //   name: 'dashboard',
  //   link: '/',
  //   depth: []
  // },
  {
    title: '유저관리',
    name: 'user',
    link: '/user',
    depth: []
  },
  {
    title: 'NEWS',
    name: 'news',
    link: '/news',
    depth: []
  },

  {
    title: 'BLOG',
    name: 'blog',
    link: '/blog',
    depth: []
  },
  {
    title: 'CONTACT',
    name: 'contact',
    link: '/contact',
    depth: []
  },
  {
    title: 'CAREER',
    name: 'career',
    depth: [
      {
        title: '지원 리스트',
        name: 'career',
        link: '/career',
        depth: []
      },
      {
        title: '카테고리 관리',
        name: 'careerCategory',
        link: '/careerCategory',
        depth: []
      }
    ]
  },
  {
    title: 'Premium',
    name: 'premium',
    depth: [
      {
        title: 'TrendReport',
        name: 'trendReport',
        link: '/premium_trendReport',
        depth: []
      }
    ]
  }
  // {
  //   title: '사이트 관리',
  //   name: 'siteInfo',
  //   link: '/siteInfo',
  //   depth: []
  // }
];

//* table
export const tableButtonList = [
  {
    id: 'detailBtn',
    title: '상세',
    kind: 'button',
    theme: 'primary',
    width: '4%'
  },
  {
    id: 'modifyBtn',
    title: '수정',
    kind: 'button',
    theme: 'warning',
    width: '4%'
  },
  {
    id: 'deleteBtn',
    title: '삭제',
    kind: 'button',
    theme: 'danger',
    width: '4%'
  }
];
export const viewCountList = [5, 10, 20, 50].map(v => ({ value: v, text: `${v}개씩 보기` }));

//* value list
export const signInValueList = [
  {
    id: 'email',
    text: '이메일',
    type: 'email'
  },
  {
    id: 'password',
    text: '비밀번호',
    type: 'password'
  }
];

export const siteInfoValueList = [
  {
    id: 'companyName',
    text: '회사명',
    type: 'text'
  },
  {
    id: 'companyAddress',
    text: '회사 주소',
    type: 'text'
  },
  {
    id: 'companyEmail',
    text: '회사 이메일',
    type: 'email'
  },
  {
    id: 'ceoName',
    text: '대표자명',
    type: 'text'
  },
  {
    id: 'companyRN',
    text: '사업자 등록 번호',
    type: 'text'
  }
];
export const postValueList = [
  {
    id: 'title',
    title: '제목',
    width: 'auto'
  },
  {
    id: 'createdAt',
    title: '등록일',
    kind: 'timestamp',
    width: '20%'
  },
  {
    id: 'updatedAt',
    title: '수정일',
    kind: 'timestamp',
    width: '20%'
  }
];

export const contactValueList = [
  {
    id: 'name',
    title: '이름',
    type: 'text',
    width: '5%'
  },
  {
    id: 'phone',
    title: '휴대폰 번호',
    type: 'text',
    width: '10%'
  },
  {
    id: 'email',
    title: '이메일',
    type: 'email',
    width: '10%'
  },
  {
    id: 'company',
    title: '기업/기관',
    type: 'text',
    width: '10%'
  },
  {
    id: 'service',
    title: '필요 서비스',
    type: 'text',
    kind: 'array',
    width: '15%'
  },
  {
    id: 'content',
    title: '문의 내용',
    type: 'textarea',
    width: 'auto',
    isAlone: true
  },
  {
    id: 'createdAt',
    title: '등록일',
    type: 'text',
    kind: 'timestamp',
    isLock: true,
    width: 'auto'
  }
];

//* 사용자
export const userSupplierInfo = {
  fireauth: '일반',
  kakao: '카카오',
  naver: '네이버',
  facebook: '페이스북'
};
export const userValueList = [
  {
    id: 'name',
    title: '이름',
    type: 'text',
    width: '8%'
  },
  {
    id: 'email',
    title: '이메일',
    type: 'email',
    width: '15%',
    isReadOnly: true
  },
  // {
  //   id: 'isEmailVerified',
  //   title: '이메일 인증',
  //   type: 'text',
  //   kind: 'boolean',
  //   width: '9%',
  //   isLock: true
  // },
  {
    id: 'phoneNum',
    title: '휴대폰 번호',
    type: 'text',
    width: '15%'
  },
  {
    id: 'isPremium',
    title: '프리미엄',
    type: 'text',
    width: '9%'
  },
  {
    id: 'createdAt',
    title: '가입일',
    kind: 'timestamp',
    width: 'auto',
    isLock: true
  },
  {
    id: 'updatedAt',
    title: '수정일',
    kind: 'timestamp',
    width: 'auto',
    isLock: true
  },
  {
    id: 'companyName',
    title: '회사명',
    type: 'text',
    width: 'auto'
  },
  {
    id: 'companyTel',
    title: '회사 전화번호',
    type: 'text',
    width: 'auto',
    isNotList: true
  },
  {
    id: 'companyPosition',
    title: '회사 직급',
    type: 'text',
    width: 'auto',
    isNotList: true
  }
];
export const careerCategoryValueList = [
  {
    id: 'parentCategory',
    title: '상위 카테고리',
    width: '20'
  },
  {
    id: 'name',
    title: '카테고리명',
    width: '20%',
    isReadOnly: true
  },
  {
    id: 'description',
    title: '카테고리 설명',
    type: 'textarea',
    width: 'auto',
    isNotList: true
  },
  {
    id: 'submission',
    title: '제출 서류 및 방법',
    type: 'textarea',
    width: 'auto',
    isNotList: true
  },
  {
    id: 'employment',
    title: '고용 형태',
    type: 'textarea',
    width: 'auto',
    isNotList: true
  },
  {
    id: 'createdAt',
    title: '등록일',
    kind: 'timestamp',
    width: '20%',
    isLock: true
  },
  {
    id: 'updatedAt',
    title: '수정일',
    kind: 'timestamp',
    width: '20%',
    isLock: true
  }
];
export const careerValueList = [
  {
    id: 'name',
    title: '이름',
    width: '5%'
  },
  {
    id: 'phone',
    title: '휴대폰 번호',
    width: '15%'
  },
  {
    id: 'parentCategory',
    title: '상위 카테고리',
    width: '20%'
  },
  {
    id: 'category',
    title: '카테고리',
    type: 'textarea',
    width: '20%'
  },
  {
    id: 'resume',
    title: '이력서',
    type: 'textarea',
    width: 'auto',
    isNotList: true
  },
  {
    id: 'addDocs',
    title: '추가 서류',
    type: 'textarea',
    width: 'auto',
    isNotList: true
  },
  {
    id: 'createdAt',
    title: '등록일',
    kind: 'timestamp',
    width: '20%',
    isLock: true
  },
  {
    id: 'updatedAt',
    title: '수정일',
    kind: 'timestamp',
    width: '20%',
    isLock: true
  }
];
