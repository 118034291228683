import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Button, ContentTitle, Content, List } from '@/components/atoms';
import { ContentInputItem, ButtonGroup } from '@/components/molecules';
import { siteInfoValueList } from '@/shared/commonData';
import { useDataCrud, useInput } from '@/hooks';
import { setIsLoading } from '@/shared/redux/common';
import { setSiteInfo, siteInfoInitialInfo } from '@/shared/redux/siteInfo';

export default function SiteInfoFormComp({ pageInfo }) {
  const dispatch = useDispatch();
  const { getDataInfo, modifyData } = useDataCrud();
  const [newSiteInfo, setNewSiteInfo, handleChangeInfo] = useInput({
    ...siteInfoInitialInfo
  });

  // 해당 id 데이터
  useEffect(() => {
    (async () => {
      const infoData = await getDataInfo(pageInfo, 'data');

      dispatch(setSiteInfo(infoData));
      dispatch(setIsLoading(false));
      setNewSiteInfo(infoData);
    })();

    return () => {
      dispatch(setSiteInfo(siteInfoInitialInfo));
    };
  }, [getDataInfo, dispatch, pageInfo, setNewSiteInfo]);

  return (
    <div>
      <Content>
        <ContentTitle>사이트 정보</ContentTitle>
        <List>
          {siteInfoValueList.map((item, idx) => (
            <ContentInputItemComp
              key={idx}
              labelTitle={item.text}
              name={item.id}
              value={newSiteInfo[item.id]}
              onChange={handleChangeInfo}
            />
          ))}
        </List>
      </Content>
      <ButtonGroup>
        <>
          <Button theme='warning' onClickCB={() => modifyData(pageInfo, 'data', newSiteInfo)}>
            수정
          </Button>
        </>
      </ButtonGroup>
    </div>
  );
}

//* style
const ContentInputItemComp = styled(ContentInputItem)`
  & > input {
    width: 100%;
  }
`;
