import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { firebase, firestore, fireStorage } from '@/firebaseInit';
import { useFireResult } from '@/hooks';
import { setIsLoading } from '@/shared/redux/common';

export default function useDataCrud() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { handleSuccess, handleError } = useFireResult();

  /**
   * fiebase timestamp
   */
  const fireTimestamp = useCallback(() => {
    return firebase.firestore.Timestamp.fromDate(new Date()).seconds;
  }, []);

  /**
   * 현재 Data 정보
   * @param {string} dataId - 데이터 id
   */
  const getDataInfo = useCallback(
    async (pageInfo, dataId) => {
      dispatch(setIsLoading(true));
      const { currPage, collection } = pageInfo;
      const currCollection = firestore.collection(collection ? collection : currPage);
      const response = await currCollection.doc(dataId).get();
      const data = response.data();

      if (!data) {
        const err = 'ERROR : not found data';
        handleDataError('글 정보를 가져오는', err);
        return { success: false, error: err };
      }

      return data;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  /**
   * 현재 리스트 정보
   * @param {string} orderValue - 정렬 기준 항목
   * @param {string} orderType - 정렬 방식
   * @param {object} search - 검색 필터링
   */
  const getDataList = useCallback(
    async (
      pageInfo,
      orderValue = 'updatedAt',
      orderType = 'desc',
      search = { name: '', value: '' }
    ) => {
      dispatch(setIsLoading(true));

      const { name, value } = search;
      const { currPage, collection } = pageInfo;
      const currCollection = firestore.collection(collection ? collection : currPage);
      const snapshot = await currCollection.orderBy(orderValue, orderType).get();
      const result = snapshot.docs.map(doc => doc.data());
      return search.name ? result.filter(v => v[`${name}`].includes(value)) : result;
    },
    [dispatch]
  );

  /**
   * 동작 성공 함수
   * @param {string} text - 특정 동작 텍스트
   */
  const handleDataSuccess = useCallback(
    (pageInfo, text) => {
      const { currPage } = pageInfo;
      handleSuccess(text);
      history.push(`/${currPage}`);
    },
    [handleSuccess, history]
  );

  /**
   * 동작 에러 함수
   * @param {string} text - 특정 동작 텍스트
   * @param {object} error - 에러 정보
   */
  const handleDataError = useCallback(
    (text, error) => {
      handleError(null, text);
      console.error(error);
    },
    [handleError]
  );

  /**
   * 데이터 등록
   * @param {string} dataId - 데이터 id
   * @param {string} info - 등록하려는 데이터 정보
   */
  const writeData = useCallback(
    async (pageInfo, dataId, info) => {
      const { currPage, collection } = pageInfo;
      const currCollection = firestore.collection(collection ? collection : currPage);
      const typeText = '등록';
      const timestamp = fireTimestamp();

      const data = {
        ...info,
        createdAt: timestamp,
        updatedAt: timestamp
      };

      try {
        await currCollection.doc(dataId).set(data);
        handleDataSuccess(pageInfo, `${typeText}이`);
      } catch (error) {
        handleDataError(typeText, error);
      }
    },
    [fireTimestamp, handleDataSuccess, handleDataError]
  );

  /**
   * 데이터 수정
   * @param {string} dataId - 데이터 id
   * @param {string} info - 수정할 데이터 정보
   */
  const modifyData = useCallback(
    async (pageInfo, dataId, info) => {
      dispatch(setIsLoading(true));

      const { currPage, collection } = pageInfo;
      const currCollection = firestore.collection(collection ? collection : currPage);
      const timestamp = fireTimestamp();
      const typeText = '수정';

      const data = {
        ...info,
        updatedAt: timestamp
      };

      if (!data.pw) delete data.pw;

      try {
        await currCollection.doc(dataId).update(data);
        handleDataSuccess(pageInfo, `${typeText}이`);
      } catch (error) {
        handleDataError(typeText, error);
      }
    },
    [dispatch, fireTimestamp, handleDataError, handleDataSuccess]
  );

  /**
   * 데이터 삭제 (소프트)
   * @param {string} dataId - 데이터 id
   * @param {object} text - 삭제할 항목 텍스트 정보
   */
  const deleteDataSoft = useCallback(
    async (pageInfo, dataId, text = { object: '글을', type: '삭제' }) => {
      const { object, type } = text;
      const { currPage, collection } = pageInfo;
      const collectionName = collection ? collection : currPage;
      const currCollection = firestore.collection(collectionName);
      const typeText = type;
      const answer = window.confirm(`해당 ${object} ${type}하시겠습니까?`);
      if (!answer) return false;

      dispatch(setIsLoading(true));

      const timestamp = fireTimestamp();
      const data = {
        isDeleted: true,
        updatedAt: timestamp
      };

      try {
        await currCollection.doc(dataId).update(data);
        handleDataSuccess(pageInfo, `${typeText}가`);
      } catch (error) {
        handleDataError(typeText, error);
      }
    },
    [dispatch, fireTimestamp, handleDataError, handleDataSuccess]
  );

  /**
   * 데이터 삭제
   * @param {string} dataId - 데이터 id
   * @param {boolean} isUseImg - 이미지 사용 여부
   * @param {object} text - 삭제할 항목 텍스트 정보
   * @param {boolean} isUseThumbnail - 썸네일 이미지 사용 여부
   * @param {boolean} isUseFile - 파일 사용 여부
   */
  const deleteData = useCallback(
    async (
      pageInfo,
      dataId,
      isUseImg = true,
      text = { object: '글을', type: '삭제' },
      isUseThumbnail = false,
      isUseFile = false
    ) => {
      const { object, type } = text;
      const { currPage, collection } = pageInfo;
      const collectionName = collection ? collection : currPage;
      const currCollection = firestore.collection(collectionName);
      const storagePath = `${currPage}/${dataId}`;
      const typeText = type;
      const answer = window.confirm(`해당 ${object} ${type}하시겠습니까?`);
      if (!answer) return false;

      dispatch(setIsLoading(true));

      // 이미지를 사용하는 경우
      if (isUseImg) {
        // 해당 글에 이미지가 있는 경우 모든 이미지 제거
        const currStorageRef = fireStorage.ref(storagePath);
        currStorageRef
          .listAll()
          .then(result =>
            result.items.forEach(imageRef => {
              currStorageRef
                .child(`/${imageRef.name}`)
                .delete()
                .then(res => res)
                .catch(error => console.error(error));
            })
          )
          .catch(error => console.error(error));

        if (isUseThumbnail) {
          // 썸네일 이미지 폴더 제거
          const currStorageThumbnailRef = fireStorage.ref(`${storagePath}/thumbnail`);
          currStorageThumbnailRef
            .listAll()
            .then(result => {
              if (result.items) {
                currStorageThumbnailRef
                  .child(`/${result.items[0].name}`)
                  .delete()
                  .then(res => res)
                  .catch(error => console.error(error));
              }
            })
            .catch(error => console.error(error));
        }
      }

      if (isUseFile) {
        const currStorageRef = fireStorage.ref(storagePath);
        currStorageRef
          .listAll()
          .then(result =>
            result.items.forEach(imageRef => {
              currStorageRef
                .child(`/${imageRef.name}`)
                .delete()
                .then(res => res)
                .catch(error => console.error(error));
            })
          )
          .catch(error => console.error(error));
      }

      // 해당 데이터 제거
      currCollection
        .doc(dataId)
        .delete()
        .then(() => {
          handleDataSuccess(pageInfo, `${typeText}가`);
        })
        .catch(error => {
          handleDataError(typeText, error);
        });
    },
    [dispatch, handleDataError, handleDataSuccess]
  );

  /**
   * 이미지 업로드
   * @param {string} dataId - 데이터 id
   * @param {string} imgId - 업로드 할 이미지의 이름(id)
   * @param {object} imgFile - 업로드 할 이미지 파일
   */
  const uploadImg = useCallback(
    async (pageInfo, dataId, imgId, imgFile, isThumbnail) => {
      const { currPage, collection } = pageInfo;
      const collectionName = collection ? collection : currPage;
      const storageRef = fireStorage.ref();
      const storagePath = isThumbnail
        ? `${collectionName}/${dataId}/thumbnail/${imgId}`
        : `${collectionName}/${dataId}/${imgId}`;

      const uploadImg = await storageRef
        .child(storagePath)
        .put(imgFile)
        .then(snapshot => snapshot)
        .catch(error => handleDataError('이미지 파일 업로드', error));

      return uploadImg.ref
        .getDownloadURL()
        .then(downloadURL => downloadURL + `&imgId=${imgId}`)
        .catch(error => error);
    },
    [handleDataError]
  );

  /**
   * 파일 업로드
   * @param {string} dataId - 데이터 id
   * @param {string} fileId - 업로드 할 파일 이름(id)
   * @param {object} fileObj - 업로드 할 파일 객체
   */
  const uploadFile = useCallback(
    async (pageInfo, dataId, fileId, fileObj) => {
      const { currPage, collection } = pageInfo;
      const collectionName = collection ? collection : currPage;
      const storageRef = fireStorage.ref();
      const storagePath = `${collectionName}/${dataId}/${fileId}`;

      const uploadFile = await storageRef
        .child(storagePath)
        .put(fileObj)
        .then(snapshot => snapshot)
        .catch(error => handleDataError('파일 업로드', error));

      return uploadFile.ref
        .getDownloadURL()
        .then(downloadURL => downloadURL)
        .catch(error => error);
    },
    [handleDataError]
  );

  /**
   * 불필요한 이미지 제거
   * @param {string} dataId - 데이터 id
   * @param {array} imgList - 에디터에 존재하는 모든 이미지
   * @param {boolean} isThumbnail - 썸네일 이미지인 경우 체크
   */
  const uploadImgCleanUp = useCallback(async (pageInfo, dataId, imgList, isThumbnail) => {
    const { currPage, collection } = pageInfo;
    const collectionName = collection ? collection : currPage;
    const storagePath = `${collectionName}/${dataId}`;
    const currStorageRef = fireStorage.ref(storagePath);
    const currStorageThumbnailRef = fireStorage.ref(`${storagePath}/thumbnail`);

    // 썸네일 이미지 관련
    if (isThumbnail) {
      let currDocThumbnailImg = [];

      // 현재 storage 에 저장된 모든 썸네일 이미지
      await currStorageThumbnailRef
        .listAll()
        .then(result => {
          result.items.forEach(imageRef => {
            currDocThumbnailImg.push(imageRef.name);
          });
        })
        .catch(error => console.error(error));

      // 사용하지 않는 썸네일 이미지 storage 에서 제거
      currStorageThumbnailRef
        .child(`/${currDocThumbnailImg[0]}`)
        .delete()
        .then(res => res)
        .catch(error => console.error(error));

      return false;
    }

    // 현재 storage 에 저장된 모든 이미지
    let currDocAllImgage = [];
    await currStorageRef
      .listAll()
      .then(result => {
        result.items.forEach(imageRef => {
          currDocAllImgage.push(imageRef.name);
        });
      })
      .catch(error => console.error(error));

    // 에디터 상의 이미지와 저장된 모든 이미지 중, 중복되지 않는 값 추출 (사용하지 않는 이미지)
    const noUseImg = currDocAllImgage.filter(id => !imgList.includes(id));

    // 사용하지 않는 이미지 storage 에서 제거
    for (let i = 0; i < noUseImg.length; i++) {
      currStorageRef
        .child(`/${noUseImg[i]}`)
        .delete()
        .then(res => res)
        .catch(error => console.error(error));
    }
  }, []);

  return {
    handleDataSuccess,
    handleDataError,
    getDataList,
    getDataInfo,
    writeData,
    modifyData,
    deleteDataSoft,
    deleteData,
    uploadImg,
    uploadFile,
    uploadImgCleanUp
  };
}
