import { configureStore } from '@reduxjs/toolkit';
import common from './common';
import auth from './auth';
import post from './post';
import contact from './contact';
import user from './user';
import career from './career';
import siteInfo from './siteInfo';
import trendReport from './trendReport';

export default configureStore({
  reducer: {
    common,
    auth,
    post,
    contact,
    user,
    career,
    siteInfo,
    trendReport
  },
  devTools: process.env.NODE_ENV !== 'production'
});
