import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Content, ContentTitle, List } from '@/components/atoms';
import { ButtonGroup, ContentTextItem } from '@/components/molecules';
import { useDataCrud } from '@/hooks';
import { timestampToDate, convertValue } from '@/utils/common';
import { setContactInfo, contactInitialInfo } from '@/shared/redux/contact';
import { setIsLoading } from '@/shared/redux/common';

export default function ContactDetailComp({ pageInfo, deleteDataCB }) {
  const dispatch = useDispatch();
  const { dataId } = useParams();
  const { contactInfo } = useSelector(state => state.contact);
  const { id, name, company, phone, email, site, service, content, createdAt } = contactInfo;
  const { getDataInfo } = useDataCrud();

  // 해당 id 데이터
  useEffect(() => {
    if (dataId) {
      (async () => {
        const infoData = await getDataInfo(pageInfo, dataId);
        dispatch(setContactInfo(infoData));
        dispatch(setIsLoading(false));
      })();

      return () => {
        dispatch(setContactInfo(contactInitialInfo.contactInfo));
      };
    }
  }, [getDataInfo, dataId, dispatch, pageInfo]);

  return (
    <>
      {id ? (
        <>
          <Content>
            <ContentTitle>문의 정보</ContentTitle>
            <List>
              <ContentTextItem labelTitle='이름' content={name} />
              <ContentTextItem labelTitle='휴대폰 번호' content={phone} />
              <ContentTextItem labelTitle='이메일' content={email} />
              <ContentTextItem labelTitle='기업/기관' content={company} />
              <ContentTextItem labelTitle='사이트' content={site} />
              <ContentTextItem labelTitle='필요 서비스' content={convertValue('array', service)} />
              <ContentTextItem labelTitle='문의 내용' content={content} />
              <ContentTextItem labelTitle='등록일' content={timestampToDate(createdAt)} />
            </List>
          </Content>
          <ButtonGroup>
            <Button theme='danger' onClickCB={() => deleteDataCB(id)}>
              삭제
            </Button>
          </ButtonGroup>
        </>
      ) : null}
    </>
  );
}
