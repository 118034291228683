import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MainTemp } from '@/components/templates';

function Main() {
  const history = useHistory();

  useEffect(() => {
    history.push('/user');
  }, [history]);

  return <MainTemp />;
}

export default Main;
