import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Table, SearchInput } from '@/components/molecules';
import { getUrlQueryValue } from '@/utils/common';
import { setUserList, userInitialInfo } from '@/shared/redux/user';
import { userValueList, tableButtonList } from '@/shared/commonData';
import { useDataCrud } from '@/hooks';
import { setIsLoading } from '@/shared/redux/common';

export default function UserTableComp({ pageInfo, deleteDataCB }) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { userList } = useSelector(state => state.user);
  const { currPage } = pageInfo;
  const { getDataList } = useDataCrud();
  const tableValueList = [...userValueList, ...tableButtonList];
  const searchValue = getUrlQueryValue(location, 'name', '');

  // 리스트 데이터
  useEffect(() => {
    (async () => {
      const listData = await getDataList(pageInfo, 'createdAt', 'desc', {
        name: 'name',
        value: searchValue
      });
      dispatch(setUserList(listData.filter(item => !item.isDeleted)));
      dispatch(setIsLoading(false));
    })();

    return () => {
      dispatch(setUserList(userInitialInfo.userList));
    };
  }, [dispatch, getDataList, pageInfo, searchValue, location]);

  // 테이블 버튼 클릭
  const handleClickBtn = useCallback(
    (dataId, btnId) => {
      const btnTypeInfo = {
        detailBtn: () => history.push(`/${currPage}/detail/${dataId}`),
        modifyBtn: () =>
          history.push({
            pathname: `/${currPage}/write/${dataId}`
          }),
        deleteBtn: () => deleteDataCB(dataId)
      };
      btnTypeInfo[btnId]();
    },
    [currPage, deleteDataCB, history]
  );

  // convert data
  const dataList = userList.map((item, idx) => {
    const { phoneAreaCode, phoneNum, isPremium } = item;
    //const phone = String(phoneAreaCode + phoneNum).replaceAll('-', '');

    return {
      ...item,
      isPremium: isPremium ? 'o' : 'x'
    };
  });

  return (
    <>
      <SearchInputBox>
        <SearchInput queryKey='name' searchValue='이름을' />
      </SearchInputBox>
      <Table
        titleList={tableValueList}
        totalCnt={dataList.length}
        currList={dataList}
        onClickBtn={handleClickBtn}
        isSubmitBtn={false}
      />
    </>
  );
}

//* style
const SearchInputBox = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
`;
