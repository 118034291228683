import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Content, ContentTitle, List } from '@/components/atoms';
import { ButtonGroup, ContentTextItem } from '@/components/molecules';
import { useDataCrud } from '@/hooks';
import { timestampToDate, convertBrTagToEnter } from '@/utils/common';
import { setCareerCategoryInfo, careerInitialInfo } from '@/shared/redux/career';
import { setIsLoading } from '@/shared/redux/common';

export default function CareerCategoryDetailComp({ pageInfo, deleteDataCB }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { dataId } = useParams();
  const { currPage } = pageInfo;
  const { careerCategoryInfo } = useSelector(state => state.career);
  const {
    id,
    name,
    parentCategory,
    description,
    shortDescription,
    qualification,
    employment,
    submission,
    createdAt,
    updatedAt
  } = careerCategoryInfo;
  const { getDataInfo } = useDataCrud();

  // 해당 id 데이터
  useEffect(() => {
    if (dataId) {
      (async () => {
        const infoData = await getDataInfo(pageInfo, dataId);
        dispatch(setCareerCategoryInfo(infoData));
        dispatch(setIsLoading(false));
      })();

      return () => {
        dispatch(setCareerCategoryInfo(careerInitialInfo.careerCategoryInfo));
      };
    }
  }, [getDataInfo, dataId, dispatch, pageInfo]);

  return (
    <>
      {id ? (
        <>
          <Content>
            <ContentTitle>카테고리 정보</ContentTitle>
            <List>
              <ContentTextItem labelTitle='상위 카테고리' content={parentCategory || '-'} />
              <ContentTextItem labelTitle='카테고리명' content={name} />
              <ContentTextItem
                labelTitle='간략 설명'
                content={convertBrTagToEnter(shortDescription)}
              />
              <ContentTextItem labelTitle='설명글' content={convertBrTagToEnter(description)} />

              <ContentTextItem
                labelTitle='지원 자격'
                content={convertBrTagToEnter(qualification)}
              />
              <ContentTextItem labelTitle='제출 서류' content={convertBrTagToEnter(submission)} />
              <ContentTextItem labelTitle='고용 형태' content={convertBrTagToEnter(employment)} />
              <ContentTextItem labelTitle='등록일' content={timestampToDate(createdAt)} />
              <ContentTextItem labelTitle='수정일' content={timestampToDate(updatedAt)} />
            </List>
          </Content>
          <ButtonGroup>
            <Button theme='warning' onClickCB={() => history.push(`/${currPage}/write/${id}`)}>
              수정
            </Button>
            <Button theme='danger' onClickCB={() => deleteDataCB(id)}>
              삭제
            </Button>
          </ButtonGroup>
        </>
      ) : null}
    </>
  );
}
