export function formValidationText() {
  return {
    minText: length => `${length}자 이상 입력해주세요.`,
    maxText: length => `${length}자 이하까지 입력해주세요.`,
    emailText: '이메일 형식이 맞지 않습니다.',
    requiredText: '필수 항목입니다.',
    passwordCkText: '입력하신 비밀번호와 일치하지 않습니다.',
    matches: type => `${type} 형식에 맞지 않습니다.`,
  };
}
