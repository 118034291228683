import { useState, useCallback } from 'react';

export default function useInput(initialState) {
  const [inputValue, setInputValue] = useState(initialState);

  const handelChangeInput = useCallback(
    e => {
      const { name, value } = e.target;

      setInputValue({
        ...inputValue,
        [name]: value
      });
    },
    [inputValue]
  );

  return [inputValue, setInputValue, handelChangeInput];
}
