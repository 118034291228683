import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function LabelComp({ ...props }) {
  return <Label {...props}>{props.children}</Label>;
}
export default React.memo(LabelComp);

//* props
LabelComp.propTypes = {
  /** 클래스 명 */
  className: PropTypes.string,
  /** label 텍스트 */
  children: PropTypes.node,
  /** label for */
  htmlFor: PropTypes.string,
  /** label 넓이값 */
  width: PropTypes.string
};

//* style
const Label = styled.label`
  display: inline-block;
  text-align: left;
  width: ${({ width }) => width};
`;
