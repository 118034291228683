import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Label } from '@/components/atoms';

/**
 * - input 으로 파일을 추가하는 경우 사용되는 컴포넌트 입니다.
 */
export default function FileInputLabelComp({
  className,
  id,
  name = '',
  labelText = '이미지 추가',
  file,
  onChange
}) {
  return (
    <FileInputLabel className={className}>
      <FileLabel htmlFor={id}>{labelText}</FileLabel>
      {file ? <FileNameText>{file.name}</FileNameText> : null}
      <FileInput id={id} name={name} type='file' onChange={onChange} />
    </FileInputLabel>
  );
}

//* props
FileInputLabelComp.propTypes = {
  /** 클래스 명 */
  className: PropTypes.string,
  /** input 아이디 */
  id: PropTypes.string,
  /** input 이름 */
  name: PropTypes.string,
  /** 라벨 텍스트 */
  labelText: PropTypes.string,
  /** file 정보 */
  //file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  file: PropTypes.object,
  /** input 변경 이벤트 */
  onChange: PropTypes.func
};

//* style
const FileInputLabel = styled.div``;
const FileLabel = styled(Label)`
  cursor: pointer;
  background: var(--color-green-5);
  color: var(--color-white-1);
  padding: 5px;
  box-sizing: border-box;
  font-size: 13px;
  border-radius: 5px;
  margin-right: 10px;
  user-select: none;
  &:hover {
    opacity: 0.9;
  }
`;
const FileInput = styled.input`
  display: none;
`;
const FileNameText = styled.span`
  font-size: 14px;
  color: var(--color-gray-5);
`;
