export function fireErrorMessage(errCoce) {
  const errObj = {
    'auth/wrong-password': '잘못된 비밀번호입니다.',
    'auth/email-already-in-use': '이미 가입된 이메일입니다.',
    'auth/user-not-found': '해당 이메일의 계정을 찾을 수 없습니다.',
    'auth/too-many-requests': '잠시 후에 다시 시도해주세요.',
  };

  return errObj[errCoce] === undefined ? '오류가 발생하였습니다.' : errObj[errCoce];
}
