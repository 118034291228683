import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ExitToApp } from '@material-ui/icons';
import { fireAuth } from '@/firebaseInit';
import { setAdminInfo } from '@/shared/redux/auth';

export default function ProfileAreaComp() {
  const dispatch = useDispatch();
  const history = useHistory();

  function adminSingOut() {
    const isSignOut = window.confirm('로그아웃 하시겠습니까?');

    if (!isSignOut) {
      return false;
    }

    fireAuth
      .signOut()
      .then(() => {
        dispatch(setAdminInfo({}));
        alert('로그아웃 되었습니다.');
        history.push('/signIn');
      })
      .catch(error => {
        alert('로그아웃 중 에러가 발생하였습니다.');
        console.error(error);
      });
  }

  return (
    <ProfileModal>
      <ExitToApp onClick={adminSingOut} />
    </ProfileModal>
  );
}

//* props
ProfileAreaComp.propTypes = {
  /** 표시 여부 */
  isShow: PropTypes.bool
};

//* style
const ProfileModal = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-white-1);
  cursor: pointer;
`;
