import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ContentItem } from '@/components/atoms';

/**
 * - 컨텐츠 항목의 상세 페이지에서 사용되는 컴포넌트입니다.
 */
export default function ContentTextItemComp({
  className,
  labelTitle,
  labelWidth,
  content,
  isWritePage
}) {
  return (
    <ContentItem className={className} labelTitle={labelTitle} labelWidth={labelWidth}>
      <ContentItemContent isWritePage={isWritePage}>{content}</ContentItemContent>
    </ContentItem>
  );
}

//* props
ContentTextItemComp.propTypes = {
  /** 클래스 명 */
  className: PropTypes.string,
  /** 라벨 텍스트 */
  labelTitle: PropTypes.string,
  /** 라벨 넓이값 */
  labelWidth: PropTypes.string,
  /** 아이템 텍스트 항목 */
  content: PropTypes.string
};

//* style
const ContentItemContent = styled.p`
  word-break: break-all;
  //padding: ${({ isWritePage }) => (isWritePage ? '10px 0' : 'auto')};
`;
