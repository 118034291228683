import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { Button, Content, ContentTitle, NoImage } from '@/components/atoms';
import { ButtonGroup } from '@/components/molecules';
import { setIsLoading } from '@/shared/redux/common';
import { setPostInfo, postInitialInfo } from '@/shared/redux/post';
import { useDataCrud } from '@/hooks';
import { ThumbnailBox, Thumbnail } from '@/components/templates/Common/Crud/Post/style';

export default function BlogDetailComp({ pageInfo, deleteDataCB }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { dataId } = params;
  const { currPage } = pageInfo;
  const { getDataInfo } = useDataCrud(pageInfo);
  const { postInfo } = useSelector(state => state.post);
  const { id, title, content, thumbnail, createdAt, updatedAt } = postInfo;

  // 해당 id 데이터
  useEffect(() => {
    if (dataId) {
      (async () => {
        const infoData = await getDataInfo(pageInfo, dataId);
        dispatch(setPostInfo(infoData));
        dispatch(setIsLoading(false));
      })();

      return () => {
        dispatch(setPostInfo(postInitialInfo.postInfo));
      };
    }
  }, [getDataInfo, dataId, dispatch, pageInfo]);

  return (
    <>
      {id ? (
        <>
          <Content>
            <div>
              <ContentTitle isLine={false}>{title}</ContentTitle>
              <PostContent>{parse(content)}</PostContent>
            </div>
            <ThumbnailBox>
              {postInfo.thumbnail ? (
                <Thumbnail src={thumbnail} alt='thumbnail-img' />
              ) : (
                <NoImage value='thumbnail' />
              )}
            </ThumbnailBox>
          </Content>
          <ButtonGroup>
            <Button theme='warning' onClickCB={() => history.push(`/${currPage}/write/${id}`)}>
              수정
            </Button>
            <Button theme='danger' onClickCB={() => deleteDataCB(id)}>
              삭제
            </Button>
          </ButtonGroup>
        </>
      ) : null}
    </>
  );
}

//* style
const PostContent = styled.div`
  img {
    max-width: 100%;
  }
  padding: 20px 0;
  border: 1px solid rgba(var(--color-blue-5-rgb), 0.2);
  border-left: 0;
  border-right: 0;
  box-sizing: border-box;
`;
