import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { NewsTemp } from '@/components/templates';
import { setPageDepthList } from '@/shared/redux/common';

export default function NewsPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const { dataId, mode } = params;

  // pageDepth
  useEffect(() => {
    dispatch(
      setPageDepthList({
        pageName: 'news',
        mode,
        dataId
      })
    );
  }, [dispatch, mode, dataId]);

  return <NewsTemp />;
}
