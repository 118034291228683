import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/storage';
import 'firebase/functions';

const apiKey =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_FIREBASE_DEV_API_KEY
    : process.env.REACT_APP_FIREBASE_PROD_API_KEY;

const firebaseConfig = {
  apiKey,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const firebaseApp = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
const firestore = firebaseApp.firestore();
const fireAuth = firebaseApp.auth();
const fireStorage = firebaseApp.storage();
const fireFunctions = firebaseApp.functions('asia-northeast3');

if (
  typeof window !== 'undefined' &&
  !firebase.apps.length &&
  process.env.NODE_ENV !== 'development'
) {
  firebase.analytics();
}

export { firebase, firestore, fireAuth, fireStorage, fireFunctions };
