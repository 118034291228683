import React from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Item, Input, Label, FormErrorText } from '@/components/atoms';
import { Form } from '@/components/molecules';
import { signInValueList } from '@/shared/commonData';
import { formValidationText } from '@/utils/form';
import { firestore, fireAuth } from '@/firebaseInit';
import { setIsLoading } from '@/shared/redux/common';
import { useFireResult } from '@/hooks';
import { encryptionPassword } from '@/utils/common';

export default function SignInFormComp() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleSuccess, handleError } = useFireResult();
  const userCollection = firestore.collection('user');
  const { emailText, requiredText, minText } = formValidationText();

  let initialValues = {};
  signInValueList.map(v => (initialValues[v.id] = ''));

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      email: Yup.string().email(emailText).required(requiredText),
      password: Yup.string().min(8, minText(8)).required(requiredText)
    }),
    onSubmit: values => {
      adminLogin(values);
    }
  });

  async function adminLogin(val) {
    const { email, password } = val;

    dispatch(setIsLoading(true));

    // 관리자 여부 체크
    const isAdmin = await userCollection
      .where('isAdmin', '==', true)
      .get()
      .then(querySnapshot => {
        let adminUser = [];

        querySnapshot.forEach(doc => {
          adminUser.push(doc.data());
        });

        return adminUser.some(val => val.email === email);
      })
      .catch(error => error);

    if (isAdmin.code) {
      handleError(isAdmin.code);
      return false;
    }

    if (!isAdmin) {
      handleError(null, '관리자만 로그인 가능합니다.', true);
      return false;
    }

    // 정상 로그인
    const loginResult = await fireAuth
      .signInWithEmailAndPassword(email, encryptionPassword(password))
      .then(user => user)
      .catch(err => err);

    if (loginResult.code) {
      handleError(loginResult.code);
      return false;
    }

    handleSuccess('로그인 되었습니다.', true);
    history.push('/user');
  }

  return (
    <SignInForm onSubmit={formik.handleSubmit} submitText='로그인'>
      {signInValueList &&
        signInValueList.map((v, i) => (
          <SignInFormItem key={i}>
            <InputBox>
              <Label htmlFor={v.id} width='100px'>
                {v.text}
              </Label>
              <SignInInput
                id={v.id}
                name={v.id}
                type={v.type ? v.type : 'text'}
                onChange={formik.handleChange}
                value={formik.values[`${v.id}`]}
              />
            </InputBox>
            {formik.touched[`${v.id}`] && formik.errors[`${v.id}`] ? (
              <FormErrorText>{formik.errors[`${v.id}`]}</FormErrorText>
            ) : null}
          </SignInFormItem>
        ))}
    </SignInForm>
  );
}

//* style
const SignInForm = styled(Form)`
  width: 520px;
  & > button {
    width: 100%;
  }
`;
const SignInFormItem = styled(Item)`
  padding: 10px 0;
  text-align: left;
`;
const InputBox = styled.div`
  display: flex;
  align-items: center;
`;
const SignInInput = styled(Input)`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
`;
