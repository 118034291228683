import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
  name: 'COMMON',
  initialState: {
    isLoading: false,
    currPage: '',
    pageDepthList: []
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setCurrPage: (state, action) => {
      state.currPage = action.payload;
    },
    setPageDepthList: (state, action) => {
      const { pageName, depthPageName, mode, dataId } = action.payload;

      function currModeText() {
        const modeTypeObj = {
          detail: '상세',
          write: '등록',
          modify: '수정'
        };

        if (mode) {
          return mode === 'write' && dataId ? modeTypeObj['modify'] : modeTypeObj[mode];
        }

        return undefined;
      }
      state.pageDepthList = ['관리자 페이지', pageName, depthPageName, currModeText()];
    }
  }
});

export const { setIsLoading, setCurrPage, setPageDepthList } = commonSlice.actions;
export default commonSlice.reducer;
