import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ArrowLeft, ArrowDropDown, AccountCircle, Brightness1 } from '@material-ui/icons';
import { List, Item, Image } from '@/components/atoms';
import { Logo } from '@/components/molecules';
import { ProfileArea } from '@/components/organisms';
import { sidebarMenuList } from '@/shared/commonData';
import { CustomScrollStyle } from '@/static/style/common';

function SidebarComp({ className }) {
  const [isShowProfileModal, setIsShowProfileModal] = useState(false);
  const { adminInfo } = useSelector(state => state.auth);
  const { pathname } = useLocation();

  // AccordionComp
  function AccordionComp({ list, active = false, pageName }) {
    const [isActive, setIsActive] = useState(active);
    const isList = list.depth.length > 0;

    return (
      <>
        <MenuItemTitleBox onClick={() => setIsActive(!isActive)}>
          <MenuItemIcon>
            <Image
              url={`${process.env.PUBLIC_URL}/images/${pageName}.svg`}
              color='var(--color-white-1)'
            />
          </MenuItemIcon>
          <p>{list.title}</p>
          {isList && isActive ? <ArrowDropDown /> : <ArrowLeft />}
        </MenuItemTitleBox>
        {isList && isActive ? (
          <MenuDepthList>
            {list.depth.map((val, idx) => (
              <Item key={idx} className='depth-menu-item-link'>
                <MenuItemLink to={val.link} activeClassName='on'>
                  <MenuItemIcon className='dot-icon-box'>
                    <Brightness1 />
                  </MenuItemIcon>
                  {val.title}
                </MenuItemLink>
              </Item>
            ))}
          </MenuDepthList>
        ) : null}
      </>
    );
  }

  useEffect(() => {
    function hideProfileModal() {
      if (isShowProfileModal) setIsShowProfileModal(false);
    }

    document.addEventListener('click', hideProfileModal);
    return () => {
      document.removeEventListener('click', hideProfileModal);
    };
  }, [isShowProfileModal]);

  return (
    <Sidebar className={className}>
      <LogoEle />
      <SidebarList>
        {sidebarMenuList &&
          sidebarMenuList.map((v, i) => (
            <MenuItem key={i}>
              {v.depth.length ? (
                <AccordionComp
                  list={v}
                  active={pathname.split('/')[1].includes(v.name)}
                  pageName={v.name}
                />
              ) : (
                <MenuItemLink exact={v.link === '/'} to={v.link} activeClassName='on'>
                  <MenuItemIcon>
                    <Image
                      url={`${process.env.PUBLIC_URL}/images/${v.name}.svg`}
                      color='var(--color-white-1)'
                    />
                  </MenuItemIcon>
                  <MenuItemText>{v.title}</MenuItemText>
                </MenuItemLink>
              )}
            </MenuItem>
          ))}
      </SidebarList>
      <SidebarBottom>
        <AdminInfo>
          <AccountCircle className='profile-icon' />
          <AdminName>{adminInfo.name}</AdminName>
        </AdminInfo>
        <ProfileArea />
      </SidebarBottom>
    </Sidebar>
  );
}
export default React.memo(SidebarComp);

//* style
const Sidebar = styled.aside`
  width: var(--size-sidebar-width);
  background: var(--color-blue-7);
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  padding: 40px 20px 20px;
  box-sizing: border-box;
`;
const LogoEle = styled(Logo)`
  margin-left: 20px;
  margin-bottom: 65px;
`;
const SidebarList = styled(List)`
  ${CustomScrollStyle}
  overflow-y: overlay;
  margin-bottom: 20px;
`;

const MenuItem = styled(Item)`
  color: var(--color-white-1);
  font-weight: 700;
  user-select: none;
`;
const MenuItemStyle = css`
  display: flex;

  align-items: center;
  display: flex;
  padding: 15px 20px;
  border-radius: 12px;
  color: var(--color-white-1);
  margin-bottom: 5px;
  &:hover {
    background: rgba(225, 225, 225, 0.1);
  }
  &.on {
    background: var(--color-blue-6);
  }
`;

const MenuItemLink = styled(NavLink)`
  ${MenuItemStyle}
`;
const MenuItemIcon = styled.div`
  display: block;
  margin-right: 25px;
  width: 25px;
  height: 25px;
`;
const MenuItemText = styled.p`
  display: inline-block;
`;
const MenuItemTitleBox = styled.div`
  ${MenuItemStyle}
  display: flex;
  align-items: center;
  & > svg {
    margin-left: auto;
  }
`;
const MenuDepthList = styled(List)`
  display: ${props => props.display};
  & > .depth-menu-item-link {
    & > a {
      padding-left: 50px;
      & > .dot-icon-box {
        margin-right: 10px;
        svg {
          font-size: 10px;
        }
      }
    }
  }
`;

const SidebarBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
`;
const AdminInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--color-white-1);
  box-sizing: border-box;
  &:hover {
    background: none;
  }
  & > .profile-icon {
    font-size: 30px;
  }
`;
const AdminName = styled.p`
  margin-left: 10px;
  font-weight: 500;
  font-size: var(--font-size-3);
  user-select: none;
`;
