import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Select } from '@/components/atoms';
import { createQueryString, getUrlQueryValue } from '@/utils/common';

/**
 * - Career 페이지에서 카테고리를 통해 리스트를 필터링하는 경우 사용하는 Select 컴포넌트입니다.
 */
export default function CareerCategorySelectComp({ pageInfo, dataList, setState }) {
  const location = useLocation();
  const history = useHistory();
  const { careerCategoryList } = useSelector(state => state.career);
  const { currPage } = pageInfo;
  const parentCategory = getUrlQueryValue(location, 'parentCategory');
  const category = getUrlQueryValue(location, 'category');
  const queryName = getUrlQueryValue(location, 'name');
  const parentCategoryListArr = [...new Set(careerCategoryList.map(item => item.parentCategory))];
  const categoryListArr = careerCategoryList.filter(item => item.parentCategory === parentCategory);

  // 카테고리 select list 추가
  let parentCategoryList = [];
  let categoryList = [];

  for (let i = 0; i < parentCategoryListArr.length; i++) {
    const value = parentCategoryListArr[i];
    parentCategoryList.push({
      value,
      text: value
    });
  }

  for (let i = 0; i < categoryListArr.length; i++) {
    const value = categoryListArr[i].name;
    categoryList.push({
      value,
      text: value
    });
  }

  // 상위 카테고리
  function handleSelectParentCategoryOption(value) {
    const search = createQueryString({ category: '', parentCategory: value }, location.search);
    history.push(search);
  }

  // 카테고리
  function handleSelectCategoryOption(value) {
    const search = createQueryString({ category: value }, location.search);
    history.push(search);
  }

  // 리스트 필터링
  useEffect(() => {
    // 검색어 대소문자 처리
    function searchQuery(value) {
      return (
        value.includes(queryName) ||
        value.includes(queryName.toUpperCase()) ||
        value.includes(queryName.toLowerCase())
      );
    }

    // 상위 카테고리
    if (parentCategory && !category) {
      if (queryName) {
        setState(
          dataList.filter(item => item.parentCategory === parentCategory && searchQuery(item.name))
        );
        return false;
      }

      setState(dataList.filter(item => item.parentCategory === parentCategory));
      return false;
    }

    // 카테고리
    if (parentCategory && category) {
      if (queryName) {
        setState(dataList.filter(item => item.category === category && searchQuery(item.name)));
        return false;
      }

      if (currPage === 'career') {
        setState(dataList.filter(item => item.category === category));
        return false;
      }
      setState(dataList.filter(item => item.name === category));
      return false;
    }

    // 검색어
    if (queryName) {
      setState(dataList.filter(item => searchQuery(item.name)));
      return false;
    }

    setState(dataList);
  }, [currPage, dataList, parentCategory, category, queryName, setState]);

  return (
    <SelectBox>
      <Select
        name='parentCategory'
        initTitle='상위 카테고리'
        width='180px'
        optionList={parentCategoryList}
        onClick={handleSelectParentCategoryOption}
        initSelect={parentCategory}
      />
      <Select
        name='category'
        initTitle='카테고리'
        width='180px'
        optionList={categoryList}
        onClick={handleSelectCategoryOption}
        initSelect={category}
      />
    </SelectBox>
  );
}

//* props
CareerCategorySelectComp.propTypes = {
  /** 필터링 하려는 리스트 */
  dataList: PropTypes.array,
  /** 필터링 리스트 업데이트 함수 */
  setState: PropTypes.func
};

//* style
const SelectBox = styled.div`
  display: flex;
  & > div {
    margin-right: 10px;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;
