import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { PageDepth } from '@/components/molecules';

/**
 * - Section 상단에 위치하는 헤더 영역입니다.
 * - 좌측에는 현재 페이지명, 우측에는 현재 페이지 단계가 표시됩니다.
 */
export default function HeaderComp({ currPage = '', className }) {
  const { pageDepthList } = useSelector(state => state.common);
  const depthListArr = pageDepthList.filter(val => val !== undefined);
  const title = pageDepthList[1] ? pageDepthList[1] : currPage;

  return (
    <Header className={className}>
      <HeaderTitle>{title}</HeaderTitle>
      <PageDepth depthList={depthListArr} />
    </Header>
  );
}

//* props
HeaderComp.propTypes = {
  /** 클래스 명 */
  className: PropTypes.string,
  /** 현재 페이지명 */
  currPage: PropTypes.string
};

//* style
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: var(--size-sidebar-width);
  z-index: 1000;
  width: calc(100% - var(--size-sidebar-width));
  height: var(--size-header-height);
  padding: 30px 40px;
  box-sizing: border-box;
  background: var(--color-white-1);
`;
const HeaderTitle = styled.h2`
  font-size: var(--font-size-1);
  font-weight: 700;
  user-select: none;
`;
