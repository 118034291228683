import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

export default function LoadingComp({ isShow = false, className }) {
  return (
    <LoadingWrap className={className} isShow={isShow}>
      <Loading className='animation-rotation' />
    </LoadingWrap>
  );
}

//* props
LoadingComp.propTypes = {
  /** 클래스 명 */
  className: PropTypes.string,
  /** 표시 여부 */
  isShow: PropTypes.bool
};

//* style
const LoadingStyle = {
  show: css`
    opacity: 1;
    visibility: visible;
    & > .animation-rotation {
      animation-play-state: running;
    }
  `,
  hide: css`
    transition: visibility 0.3s, opacity 0.3s;
    opacity: 0;
    visibility: hidden;
    & > .animation-rotation {
      animation-play-state: paused;
    }
  `
};
const LoadingWrap = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--color-white-2);
  z-index: 5000;
  display: flex;
  ${({ isShow }) => (isShow ? LoadingStyle.show : LoadingStyle.hide)}
  align-items: center;
  justify-content: center;
  //transition: visibility 0.3s, opacity 0.3s;
`;
const Loading = styled.div`
  width: 80px;
  height: 80px;
  border-top: 4px solid var(--color-blue-5);
  border-right: 4px solid transparent;
  border-radius: 50%;
  animation: rotation 0.5s linear infinite;
`;
