import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * - 각각의 컨텐츠를 감싸는 컨텐츠 박스 컴포넌트입니다.
 * - `isFull` 을 통해 컨텐츠 박스의 높이 값을 설정할 수 있습니다.
 * - `isMargin` 을 통해 컨텐츠 박스의 하단 간격을 설정할 수 있습니다.
 */
export default function ContentComp({ ...props }) {
  const { children } = props;
  return <Content {...props}>{children}</Content>;
}

ContentComp.propTypes = {
  /** 컨텐츠 박스 클래스 명 */
  className: PropTypes.string,
  /** 컨텐츠 박스 내용 */
  children: PropTypes.node,
  /** 컨텐츠 박스 높이값 100% 여부 */
  isFull: PropTypes.bool,
  /** 컨텐츠 박스 하단 간격 여부 */
  isMargin: PropTypes.bool
};

const Content = styled.div`
  min-height: ${({ isFull }) => (isFull ? `var(--size-content-full-height)` : 'auto')};
  margin: ${({ isMargin }) => (isMargin ? '0 0 20px 0' : 'auto')};
  padding: 20px;
  box-sizing: border-box;
  background: var(--color-white-1);
  border-radius: 12px;
`;
