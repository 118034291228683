import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Button, List, Item } from '@/components/atoms';
import { createQueryString } from '@/utils/common';
import { FirstPage, LastPage, MoreHoriz } from '@material-ui/icons';

export default function PaginationComp({ totalCnt, maxPageCnt = 5, viewCount, currPage }) {
  const history = useHistory();
  const location = useLocation();
  const pageListCnt = Math.ceil(totalCnt / viewCount);

  // 데이터의 총 개수 (= totalCnt)
  const pageListArr = [...Array(pageListCnt)].map((v, i) => i + 1);

  // 일반적인 경우 최대로 보여줄 페이지 버튼 개수 / 2
  const ellipsisCnt = Math.ceil(maxPageCnt / 2);

  // 생략 시작 분기점 (해당 값 이상이 되면 생략 시작)
  const startEllipsis = ellipsisCnt + 1;

  // 생략 종료 분기점 (해당 값 이하가 되면 생략 시작)
  const endEllipsis = pageListCnt - ellipsisCnt;

  function paginationValue() {
    if (currPage <= ellipsisCnt) {
      return pageListArr.slice(0, maxPageCnt);
    }

    // 현재 페이지가 ~ endEllipsis 인 경우
    if (currPage > endEllipsis) {
      return pageListArr.slice(endEllipsis - (ellipsisCnt - 1), pageListCnt);
    }

    // 현재 페이지가 startEllipsis ~ 인 경우
    if (currPage >= startEllipsis) {
      return pageListArr.slice(currPage - 2, currPage + 1);
    }

    return [];
  }

  // 페이지 리스트
  const pageList = paginationValue();

  const handleClickPage = useCallback(
    page => {
      const search = createQueryString({ viewCount, page }, location.search);

      history.push(search);
    },
    [history, viewCount, location]
  );

  function handleClickPageArrow(type) {
    const page = type === 'prev' ? 1 : pageListCnt;

    const search = createQueryString({ viewCount, page }, location.search);
    history.push(search);
  }

  function ellipsisComp(x, min, max) {
    // 총 페이지의 개수가 최대 페이지의 개수 이하인 경우 생략 아이콘 hide
    if (pageListCnt <= maxPageCnt) {
      return null;
    }

    // 현재 페이지가 분기점 사이의 값인 경우 생략 아이콘 show
    const range = (x - min) * (x - max) <= 0;

    return range ? (
      <PageDotItem>
        <MoreHoriz />
      </PageDotItem>
    ) : null;
  }

  return (
    <List display='flex'>
      <PageItem>
        <PageBtn onClickCB={() => handleClickPageArrow('prev')}>
          <FirstPage />
        </PageBtn>
      </PageItem>
      {ellipsisComp(currPage, startEllipsis, endEllipsis)}
      {pageList.map((v, i) => {
        return (
          <PageItem key={i} className={`${currPage === v ? 'on' : null}`}>
            <PageBtn onClickCB={() => handleClickPage(v)}>{v}</PageBtn>
          </PageItem>
        );
      })}
      {ellipsisComp(currPage, startEllipsis, endEllipsis)}
      <PageItem>
        <PageBtn onClickCB={() => handleClickPageArrow('next')}>
          <LastPage />
        </PageBtn>
      </PageItem>
    </List>
  );
}

//* props
PaginationComp.propTypes = {
  /** 총 데이터 개수 */
  totalCnt: PropTypes.number,
  /** 최대 페이지 버튼 개수 */
  maxPageCnt: PropTypes.number,
  /** 한 페이지에 보여줄 데이터 개수 */
  viewCount: PropTypes.number,
  /** 현재 페이지 */
  currPage: PropTypes.number
};

//* style
const PageItemStyles = css`
  width: 1.875rem;
  margin-right: 30px;
  box-sizing: border-box;
`;
const PageItem = styled(Item)`
  ${PageItemStyles}

  &:last-of-type {
    margin-right: 0;
  }
  color: var(--color-gray-3);
  &.on {
    color: var(--color-black-2);
  }
`;
const PageBtn = styled(Button)`
  width: 100%;
  height: 100%;
  background: none;
  padding: 0.313rem 0px;
  font-size: 1rem;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  font-weight: 500;
  color: inherit;
  svg {
    font-size: 1.125rem;
    color: var(--color-black-2);
  }
`;

const PageDotItem = styled(Item)`
  ${PageItemStyles}
  display: flex;
  align-items: center;
  justify-content: center;
`;
