import { createSlice } from '@reduxjs/toolkit';

export const trendReportInitialState = {
  trendReportInfo: {
    file: null,
    updatedAt: 0
  }
};

export const trendReportSlice = createSlice({
  name: 'TRENDREPORT',
  initialState: trendReportInitialState,
  reducers: {
    setTrendReportInfo: (state, action) => {
      state.trendReportInfo = action.payload;
    }
  }
});

export const { setTrendReportInfo } = trendReportSlice.actions;
export default trendReportSlice.reducer;
